<nav class="navbar navbar-expand-lg navbar-light pt-3 pb-2 pl-3 pr-3 mb-1">
  <div class="container">
    <img
      width="1"
      height="1"
      style="width: 200px !important"
      class="img-VTC w-100"
      src="https://res.cloudinary.com/danfer13/image/upload/v1658945081/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Frame_puqtrt.webp"
      alt="{{ propsComponent[1].alt }}"
    />
    <div class="nav-item-ws d-ws-block" style="padding-top: 2px; text-align: center">
      <a
        target="_blank"
        href="https://api.whatsapp.com/send?phone={{
          texts['WS_NUMBER']
        }}&text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20Asesoria%20Migratoria"
      >
        <img src="assets/images/ws-header.png" width="50" alt=""
      /></a>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon">
        <img width="30" src="assets/images/menu-mb.png" alt="" />
      </span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav" style="margin-left: auto">
        <li *ngIf="asesoria != ''" class="nav-item nav-first">
          <a class="nav-link active text-white" aria-current="page" href="{{ asesoria }}">
            <img height="30" src="assets/images/asesory-nav.png" alt="" /><br />
            Asesoría migratoria</a
          >
        </li>
        <li *ngIf="asesoriaEstudios != ''" class="nav-item">
          <a class="nav-link active text-white" aria-current="page" href="{{ asesoriaEstudios }}"
            ><img height="30" src="assets/images/students-nav.png" alt="" /><br />Asesoria para estudiantes</a
          >
        </li>
        <li class="nav-item">
          <a routerLink="/nosotros" class="nav-link active text-white" aria-current="page" href="#">
            <img height="30" src="assets/images/icono-05.png" alt="" /><br />
            Quiénes somos</a
          >
        </li>
        <li class="nav-item">
          <a routerLink="/preguntas-frecuentes" class="nav-link active text-white" aria-current="page" href="#"
            ><img height="30" src="assets/images/questions-nav.png" alt="" /><br />Preguntas frecuentes</a
          >
        </li>
        <li class="nav-item nav-blog">
          <a class="nav-link text-white" aria-current="page" href="{{ blogUrl }}">
            <img height="30" src="assets/images/blog-nav.png" alt="" /><br />Blog</a
          >
        </li>
        <li class="nav-item-ws d-ws-none" style="padding-top: 2px; text-align: center">
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone={{
              texts['WS_NUMBER']
            }}&text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20Asesoria%20Migratoria"
          >
            <img src="assets/images/ws-header.png" width="50" alt=""
          /></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="slideshow-container">
  <div
    class="mySlides mySlidesEc mySlidesCo mySlidesMain fade2"
    style="background-image: 'url(/assets/images/banner-globes.webp)'"
  >
    <div class="container d-none d-lg-block">
      <img
        width="1"
        height="1"
        src="/assets/images/logo-orbita.png"
        alt="logo-orbita"
        style="width: 180px; height: auto; margin-top: 20px"
      />
    </div>
    <div class="container d-lg-none" style="text-align: right">
      <img
        width="1"
        height="1"
        src="/assets/images/logo-orbita.png"
        alt="logo-orbita"
        style="width: 150px; height: auto; margin-top: 20px"
      />
    </div>
    <div class="container pb-5 mt-2">
      <div class="row pb-5">
        <div class="col-12 col-md-6 col-lg-7 justify-content-end align-items-center flex-colunm d-flex">
          <h1 class="font-size-lg-50 text-white mt-lg-4 d-none d-lg-block">
            <br /><br />
            {{ texts.MAIN_FIRST }} <br />
            {{ texts.MAIN_SECOND }}
          </h1>
          <h2 class="font-size-lg-55 mt-1 text-white mt-lg-4 text-center d-lg-none">
            <br /><br /><br /><br /><br />
            {{ texts.SECONDARY }}
          </h2>
        </div>
        <div class="d-none-md col-lg-1"></div>
      </div>
    </div>
  </div>
  <div class="form-container" style="overflow: hidden; padding-bottom: 20px">
    <div class="row">
      <div class="col-md-6 col-lg-8"></div>
      <div class="col-md-6 col-lg-4">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="content-quote text-center p-4 mt-lg-4">
                <div class="row">
                  <div class="col-12">
                    <h2 class="font-size-14">{{ texts.ADVISORY_FORM }}</h2>
                  </div>
                </div>
                <div class="row">
                  <form [formGroup]="form" (submit)="sendCustomer()">
                    <div class="row">
                      <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
                        <select
                          formControlName="destiny_id"
                          class="form-select ps-5 icon-location h-45 font-size-15"
                          id="destiny_id"
                        >
                          <option *ngFor="let visa of listvisas.data" value="{{ visa.id }}">{{ visa.name }}</option>
                        </select>
                      </div>
                      <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
                        <input
                          type="text"
                          class="form-control ps-5 icon-2 h-45 font-size-15"
                          id="name"
                          aria-describedby="name"
                          placeholder="Nombre"
                          name="name"
                          formControlName="name"
                          autocomplete="off"
                          required
                        />
                      </div>
                      <div class="col-12 text-end">
                        <small *ngIf="form.get('name')?.invalid && form.get('name')?.touched" class="text-danger"
                          >Nombre invalido</small
                        >
                      </div>
                      <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
                        <input
                          type="text"
                          class="form-control ps-5 icon-2 h-45 font-size-15"
                          id="name"
                          aria-describedby="lastname"
                          placeholder="Apellido"
                          name="lastname"
                          formControlName="lastname"
                          autocomplete="off"
                          required
                        />
                      </div>
                      <div class="col-12 text-end">
                        <small
                          *ngIf="form.get('lastname')?.invalid && form.get('lastname')?.touched"
                          class="text-danger"
                          >Apellido invalido</small
                        >
                      </div>
                      <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
                        <input
                          type="email"
                          class="form-control ps-5 icon-3 h-45 font-size-15"
                          id="email"
                          aria-describedby="email"
                          placeholder="Correo"
                          name="email"
                          formControlName="email"
                          autocomplete="off"
                          required
                        />
                      </div>
                      <div class="col-12 text-end">
                        <small *ngIf="form.get('email')?.invalid && form.get('email')?.touched" class="text-danger"
                          >Dirección de correo invalida</small
                        >
                      </div>
                      <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
                        <input
                          type="phone"
                          class="form-control ps-5 icon-4 h-45 font-size-15"
                          id="phone"
                          placeholder="Teléfono"
                          name="phone"
                          formControlName="phone"
                          autocomplete="off"
                          required
                        />
                      </div>
                      <div class="col-12 text-end">
                        <small *ngIf="form.get('phone')?.invalid && form.get('phone')?.touched" class="text-danger"
                          >Número de Teléfono Invalido</small
                        >
                      </div>
                      <div class="text-center d-flex justify-content-center align-items-center mt-3">
                        <input
                          type="checkbox"
                          id="terms"
                          formControlName="terms"
                          style="margin-right: 5px"
                          (click)="togglePolicies()"
                        />
                        <a
                          href="/politicas-privacidad"
                          style="color: black; font-size: 11px; text-align: left; margin-left: -3px"
                          target="_blank"
                        >
                          Acepto recibir información a través <br />
                          de mis datos proporcionados.</a
                        >
                      </div>
                      <div class="text-center d-flex justify-content-center align-items-center mt-3">
                        <re-captcha
                          formControlName="recaptcha"
                          (resolved)="onCaptchaResolved($event)"
                          siteKey="6LdHpxEhAAAAAOpAaH2fysIc3sQFbZ9hBW1gVJgJ"
                        ></re-captcha>
                      </div>

                      <div
                        class="col-12 justify-content-center align-items-center flex-colunm d-flex h-10"
                        style="margin-top: -14px"
                      >
                        <button
                          class="btn text-white p-2 bolder-g ps-lg-3 pe-lg-3 border-rad relative font-size-15"
                          style="background-color: #d10000"
                          [disabled]="form.invalid || formSent || !recaptchaValidated || !policiesAccepted"
                        >
                          <span
                            *ngIf="loading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {{ texts.MY_ADVISORY }}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-5 top-mov">
  <div class="row">
    <div class="col-12">
      <h2 class="font-size-20 bolder-g text-center text-lg-start font-size-lg-30">
        {{ texts.ADVISORIES_SECTION_MAIN }}
      </h2>
    </div>
    <div class="col-12">
      <span class="font-size-16 text-center text-lg-start font-size-lg-18">
        {{ texts.ADVISORIES_SECTION_DESC }}
      </span>
    </div>
  </div>
</div>
<div class="container mt-lg-3 mt-md-5">
  <div class="row">
    <div class="col-md-12 col-lg-5 justify-content-start align-items-center flex-colunm d-flex">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-12">
            <img
              width="1"
              height="1"
              class="w-100"
              src="{{ propsComponent[2].src }}"
              alt="{{ propsComponent[2].alt }}"
            />
            <!-- <img
              width="auto"
              height="auto"
              class="w-100"
              src="https://res.cloudinary.com/danfer13/image/upload/v1657040005/VTC%20-%20IMAGES/asesoria-migratoria_znznz3.png"
              alt="Visa Travel Center"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-3">
      <div class="">
        <div class="row">
          <div class="col-12 col-md-12 col-md-6">
            <div class="contenedor_visa mt-3 pb-3 p-lg-0 mt-lg-0 req-visa">
              <div class="row">
                <h3 class="col-12 text-center mt-3">
                  <span class="font-size-20 bolder-g">{{ texts.VISA_REQUIREMENTS_FIRST }}</span>
                  <span class="font-size-20 text-primary bolder-g"> {{ texts.VISA_REQUIREMENTS_SECOND }}</span>
                </h3>
              </div>
              <div class="row mt-3">
                <div class="col-6 col-md-12 col-lg-12">
                  <div class="row h-60">
                    <div class="col-5 col-lg-4 justify-content-end align-items-center flex-colunm d-flex">
                      <img
                        width="1"
                        height="1"
                        src="{{ propsComponent[3].src }}"
                        alt="{{ propsComponent[3].alt }}"
                        class="icon-visa icon-req"
                      />
                    </div>
                    <div class="col-7 justify-content-start align-items-center flex-colunm d-flex">
                      <p class="font-size-15 font-size-lg-15 p">Pasaporte vigente</p>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-lg-12">
                  <div class="row h-60">
                    <div class="col-5 col-lg-4 justify-content-end align-items-center flex-colunm d-flex">
                      <img
                        width="1"
                        height="1"
                        src="{{ propsComponent[4].src }}"
                        alt="{{ propsComponent[4].alt }}"
                        class="icon-visa"
                      />
                    </div>
                    <div class="col-7 justify-content-start align-items-center flex-colunm d-flex">
                      <p class="font-size-15 font-size-lg-15 p">
                        Seguro de <br />
                        Viaje
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-lg-12">
                  <div class="row h-60">
                    <div class="col-5 col-lg-4 justify-content-end align-items-center flex-colunm d-flex">
                      <img
                        width="1"
                        height="1"
                        src="{{ propsComponent[5].src }}"
                        alt="{{ propsComponent[5].alt }}"
                        class="icon-visa"
                      />
                    </div>
                    <div class="col-7 justify-content-start align-items-center flex-colunm d-flex">
                      <p class="font-size-15 font-size-lg-15 p">Justificación economica</p>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-lg-12">
                  <div class="row h-60">
                    <div class="col-5 col-lg-4 justify-content-end align-items-center flex-colunm d-flex">
                      <img
                        width="1"
                        height="1"
                        src="{{ propsComponent[6].src }}"
                        alt="{{ propsComponent[6].alt }}"
                        class="icon-visa"
                      />
                    </div>
                    <div class="col-7 justify-content-start align-items-center flex-colunm d-flex">
                      <p class="font-size-15 font-size-lg-15 p">Reserva de hotel y vuelo</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center mt-3 mt-lg-0">
            <h3>
              <span class="font-size-20 bolder-g">{{ texts.HOW_ADVISORY_FIRST }} </span> <br />
              <span class="font-size-20 text-primary bolder-g"> {{ texts.HOW_ADVISORY_SECOND }}</span>
            </h3>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2">
            <img
              class="img-brnumeros"
              width="1"
              height="1"
              src="{{ propsComponent[7].src }}"
              alt="{{ propsComponent[7].alt }}"
            />
          </div>
          <div class="col-10">
            <div class="col-12 font-size-15 cont-text-bar justify-content-start align-items-start flex-colunm d-flex">
              Recibirás un asesor legal que responderá todas tus dudas
            </div>
            <div class="col-12 font-size-15 cont-text-bar justify-content-center align-items-center flex-colunm d-flex">
              Te ayudará a reunir los documentos y a llenar formularios
            </div>
            <div class="col-12 font-size-15 cont-text-bar justify-content-center align-items-center flex-colunm d-flex">
              Te dará los consejos para que logres una entrevista exitosa
            </div>
            <div class="col-12 font-size-15 cont-text-bar justify-content-end align-items-end flex-colunm d-flex">
              Celebrará contigo la aprobación de tu visa
            </div>
            <div class="col-12 mt-3">
              <a href="#name" class="btn btn-danger bolder-g font-size-15 ps-3 pe-3"
                >Sí, quiero mi asesoría migratoria</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-3 mt-lg-5 d-md-none d-lg-none">
  <div class="row">
    <div class="col-12">
      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active text-center">
            <img
              width="1"
              height="1"
              src="{{ propsComponent[8].src }}"
              class="d-block w-100 img-asimed"
              alt="{{ propsComponent[8].alt }}"
            />
          </div>
          <div class="carousel-item text-center">
            <img
              width="1"
              height="1"
              src="{{ propsComponent[9].src }}"
              class="d-block w-100 img-asimed"
              alt="{{ propsComponent[9].alt }}"
            />
          </div>
          <div class="carousel-item text-center">
            <img
              width="1"
              height="1"
              src="{{ propsComponent[10].src }}"
              class="d-block w-100 img-asimed"
              alt="{{ propsComponent[10].alt }}"
            />
          </div>
          <div class="carousel-item text-center">
            <img
              width="1"
              height="1"
              src="{{ propsComponent[11].src }}"
              class="d-block w-100 img-asimed"
              alt="{{ propsComponent[11].alt }}"
            />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container d-none d-md-block d-lg-flex">
  <div class="row w-100">
    <div class="col-md-12 col-lg-12 mt-5">
      <h2 class="font-size-20 bolder-g text-center text-lg-start font-size-lg-25 text-uppercase">
        {{ texts.OTHERS_SECTION_MAIN }}
      </h2>
      <div class="row mt-5">
        <div class="col-md-3 col-lg-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[8].src }}"
            class="d-block w-100 img-asimed"
            alt="{{ propsComponent[8].alt }}"
          />
        </div>
        <div class="col-md-3 col-lg-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[9].src }}"
            class="d-block w-100 img-asimed"
            alt="{{ propsComponent[9].alt }}"
          />
        </div>
        <div class="col-md-3 col-lg-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[10].src }}"
            class="d-block w-100 img-asimed"
            alt="{{ propsComponent[10].alt }}"
          />
        </div>
        <div class="col-md-3 col-lg-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[11].src }}"
            class="d-block w-100 img-asimed"
            alt="{{ propsComponent[11].alt }}"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-3 mt-lg-5" *ngIf="texts['KUSHKI_COUNTRY'] == 'co' || texts['KUSHKI_COUNTRY'] == 'ec'">
  <div class="row">
    <div class="col-12">
      <h2 class="font-size-20 bolder-g text-center text-uppercase text-lg-start font-size-lg-25">
        Visítanos en nuestras oficinas
      </h2>
    </div>
  </div>
</div>
<div class="container mt-3" *ngIf="texts['KUSHKI_COUNTRY'] == 'co'">
  <div class="row">
    <div class="col-12 mt-3">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.520689130436!2d-74.0478266!3d4.6791709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9a917dd6e2cf%3A0x52f74b3beb7ea651!2sVisa%20Travel%20Center!5e0!3m2!1ses!2sve!4v1704255261877!5m2!1ses!2sve"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</div>
<div class="container mt-4" *ngIf="texts['KUSHKI_COUNTRY'] == 'ec'">
  <div class="row">
    <div class="col-12 mt-3">
      <span style="font-weight: bold; font-size: 20px"
        >Ciudad:
        <br />
        <button
          *ngIf="cityActive != 'QUITO'"
          class="btn btn-secondary px-4 font-size-15 text-white mt-2"
          (click)="changeCity('QUITO')"
        >
          QUITO
        </button>
        <button *ngIf="cityActive == 'QUITO'" class="btn btn-danger px-4 font-size-15 text-white mt-2">QUITO</button>
        <button
          *ngIf="cityActive != 'GUAYAQUIL'"
          class="btn btn-secondary px-4 font-size-15 text-white mt-2"
          (click)="changeCity('GUAYAQUIL')"
        >
          GUAYAQUIL
        </button>
        <button *ngIf="cityActive == 'GUAYAQUIL'" class="btn btn-danger px-4 font-size-15 text-white mt-2">
          GUAYAQUIL
        </button>
        <button
          *ngIf="cityActive != 'CUENCA'"
          class="btn btn-secondary px-4 font-size-15 text-white mt-2"
          (click)="changeCity('CUENCA')"
        >
          CUENCA
        </button>
        <button *ngIf="cityActive == 'CUENCA'" class="btn btn-danger px-4 font-size-15 text-white mt-2">CUENCA</button>
      </span>
    </div>
    <div class="col-12 mt-3 mb-2">
      <span style="font-weight: bold; font-size: 20px"
        >Oficina:
        <br />
        <button
          *ngIf="officeActive != 'LIBERTAD' && cityActive == 'QUITO'"
          class="btn btn-secondary px-4 font-size-15 text-white mt-2"
          (click)="changeOffice('LIBERTAD')"
        >
          LIBERTAD
        </button>
        <button
          *ngIf="officeActive == 'LIBERTAD' && cityActive == 'QUITO'"
          class="btn btn-danger px-4 font-size-15 text-white mt-2"
        >
          LIBERTAD
        </button>
        <button
          *ngIf="officeActive != 'BLS' && cityActive == 'QUITO'"
          class="btn btn-secondary px-4 font-size-15 text-white mt-2"
          (click)="changeOffice('BLS')"
        >
          BLS
        </button>
        <button
          *ngIf="officeActive == 'BLS' && cityActive == 'QUITO'"
          class="btn btn-danger px-4 font-size-15 text-white mt-2"
        >
          BLS
        </button>
        <button
          *ngIf="officeActive != 'CENTRO VIAJERO' && cityActive == 'QUITO'"
          class="btn btn-secondary px-4 font-size-15 text-white mt-2"
          (click)="changeOffice('CENTRO VIAJERO')"
        >
          CENTRO VIAJERO
        </button>
        <button
          *ngIf="officeActive == 'CENTRO VIAJERO' && cityActive == 'QUITO'"
          class="btn btn-danger px-4 font-size-15 text-white mt-2"
        >
          CENTRO VIAJERO
        </button>
        <button
          *ngIf="officeActive != 'CENTRO' && cityActive == 'GUAYAQUIL'"
          class="btn btn-secondary px-4 font-size-15 text-white mt-2"
          (click)="changeOffice('CENTRO')"
        >
          CENTRO
        </button>
        <button
          *ngIf="officeActive == 'CENTRO' && cityActive == 'GUAYAQUIL'"
          class="btn btn-danger px-4 font-size-15 text-white mt-2"
        >
          CENTRO
        </button>
        <button
          *ngIf="officeActive != 'PARQUE EMPRESARIAL' && cityActive == 'GUAYAQUIL'"
          class="btn btn-secondary px-4 font-size-15 text-white mt-2"
          (click)="changeOffice('PARQUE EMPRESARIAL')"
        >
          PARQUE EMPRESARIAL
        </button>
        <button
          *ngIf="officeActive == 'PARQUE EMPRESARIAL' && cityActive == 'GUAYAQUIL'"
          class="btn btn-danger px-4 font-size-15 text-white mt-2"
        >
          PARQUE EMPRESARIAL
        </button>
        <button *ngIf="cityActive == 'CUENCA'" class="btn btn-danger px-4 font-size-15 text-white mt-2">CUENCA</button>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-3" *ngIf="officeActive == 'LIBERTAD'">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7943101230244!2d-78.48955409999999!3d-0.1987739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59bdca4f1dafd%3A0x70ef1b1f8d45f45c!2sVisa%20Travel%20Center!5e0!3m2!1ses!2sve!4v1705696260970!5m2!1ses!2sve"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="col-12 mt-3" *ngIf="officeActive == 'BLS'">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.793932846012!2d-78.4880781!3d-0.20032949999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59a6d166f8203%3A0x58031c993602272d!2sVisa%20Travel%20Center!5e0!3m2!1ses!2sve!4v1705696991751!5m2!1ses!2sve"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="col-12 mt-3" *ngIf="officeActive == 'CENTRO VIAJERO'">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15959.176918627609!2d-78.4900514!3d-0.1991067!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59a6c513b63bb%3A0x4dcc5896e0513074!2sSeguro%20para%20viaje!5e0!3m2!1ses!2sve!4v1705697087510!5m2!1ses!2sve"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="col-12 mt-3" *ngIf="officeActive == 'CENTRO'">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.9065362728365!2d-79.89720679999999!3d-2.1891073999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d6ddfffd54d8b%3A0x58afa05a79581f43!2sVisa%20Travel%20Center!5e0!3m2!1ses!2sve!4v1705697145438!5m2!1ses!2sve"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="col-12 mt-3" *ngIf="officeActive == 'PARQUE EMPRESARIAL'">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.0075169472384!2d-79.90543819999999!3d-2.1508088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d6d1d32a759c1%3A0xc84164270cf1ec32!2sVisa%20Travel%20Center!5e0!3m2!1ses!2sve!4v1705697191913!5m2!1ses!2sve"
        height="450"
        width="100%"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="col-12 mt-3" *ngIf="officeActive == 'CUENCA'">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15938.851962709226!2d-79.0048666!3d-2.8988069!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91cd1931cb04eabb%3A0xd5372b1fe5f857f2!2sVisa%20Travel%20Center!5e0!3m2!1ses!2sve!4v1705697234815!5m2!1ses!2sve"
        height="450"
        width="100%"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</div>
<div class="container mt-3 mt-lg-5">
  <div class="row">
    <div class="col-12">
      <h2 class="font-size-20 bolder-g text-center text-uppercase text-lg-start font-size-lg-25">
        {{ texts.ACHIEVEMENTS_SECTION_MAIN }}
      </h2>
    </div>
  </div>
</div>
<div class="container-fluid d-lg-none" style="background-color: #e0e0e0; min-height: 270px">
  <div class="row">
    <div class="col-12">
      <div id="carouselExampleIndicators2" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row mt-3">
              <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
                <img
                  width="1"
                  height="1"
                  src="{{ propsComponent[12].src }}"
                  class="d-block img-slider"
                  alt="{{ propsComponent[12].alt }}"
                />
              </div>
              <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
                <img
                  width="1"
                  height="1"
                  src="{{ propsComponent[13].src }}"
                  class="d-block img-slider banderas"
                  alt="{{ propsComponent[13].alt }}"
                />
              </div>
              <div class="col-12 pb-3">
                <p class="font-size-18 text-center bolder-g">90% de visas aprobadas</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row mt-3">
              <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
                <img
                  width="1"
                  height="1"
                  src="{{ propsComponent[14].src }}"
                  class="d-block img-slider"
                  alt="{{ propsComponent[14].alt }}"
                />
              </div>
              <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
                <img
                  width="1"
                  height="1"
                  src="{{ propsComponent[15].src }}"
                  class="d-block img-slider banderas"
                  alt="{{ propsComponent[15].alt }}"
                />
              </div>
              <div class="col-12 pb-3">
                <p class="font-size-18 text-center bolder-g">85% de visas aprobadas</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row mt-3">
              <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
                <img
                  width="1"
                  height="1"
                  src="{{ propsComponent[16].src }}"
                  class="d-block img-slider"
                  alt="{{ propsComponent[16].alt }}"
                />
              </div>
              <div class="col-12">
                <p class="font-size-25 bolder-g text-center p">+ de 300.000</p>
                <p class="font-size-15 text-center p pb-4">viajeros han confiado en nosotros</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row mt-3">
              <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
                <img
                  width="1"
                  height="1"
                  src="{{ propsComponent[17].src }}"
                  class="d-block img-slider"
                  alt="{{ propsComponent[17].alt }}"
                />
              </div>
              <div class="col-12">
                <p class="font-size-25 bolder-g text-center p">+ de 25.657</p>
                <p class="font-size-18 text-center p pb-4">visas aprobadas</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row mt-3">
              <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
                <img
                  width="1"
                  height="1"
                  src="{{ propsComponent[18].src }}"
                  class="d-block img-slider"
                  alt="{{ propsComponent[18].alt }}"
                />
              </div>
              <div class="col-12">
                <p class="font-size-25 bolder-g text-center p">16 años</p>
                <p class="font-size-18 text-center p pb-4">acompañando los sueños de viaje de los latinoamericanos</p>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators2"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators2"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid bg-gris d-none d-lg-flex">
  <div class="container">
    <div class="row justify-content-center align-items-center flex-colunm d-flex">
      <div class="col">
        <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[12].src }}"
            class="d-block img-slider"
            alt="{{ propsComponent[12].alt }}"
          />
        </div>
        <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[13].src }}"
            class="d-block img-slider banderas"
            alt="{{ propsComponent[13].alt }}"
          />
        </div>
        <div class="col-12 pb-3">
          <p class="font-size-18 text-center bolder-g">90% de visas aprobadas</p>
        </div>
      </div>
      <div class="col">
        <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[14].src }}"
            class="d-block img-slider"
            alt="{{ propsComponent[14].alt }}"
          />
        </div>
        <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[15].src }}"
            class="d-block img-slider banderas"
            alt="{{ propsComponent[15].alt }}"
          />
        </div>
        <div class="col-12 pb-3">
          <p class="font-size-18 text-center bolder-g">85% de visas aprobadas</p>
        </div>
      </div>
      <div class="col">
        <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[16].src }}"
            class="d-block img-slider"
            alt="{{ propsComponent[16].alt }}"
          />
        </div>
        <div class="col-12">
          <p class="font-size-25 bolder-g text-center p">+ de 300.000</p>
          <p class="font-size-15 text-center p pb-4">viajeros han confiado en nosotros</p>
        </div>
      </div>
      <div class="col">
        <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[17].src }}"
            class="d-block img-slider"
            alt="{{ propsComponent[17].alt }}"
          />
        </div>
        <div class="col-12">
          <p class="font-size-25 bolder-g text-center p">+ de 25.657</p>
          <p class="font-size-15 text-center p pb-4">visas aprobadas</p>
        </div>
      </div>
      <div class="col mt-3">
        <div class="col-12 justify-content-center align-items-center flex-colunm d-flex pb-3">
          <img
            width="1"
            height="1"
            src="{{ propsComponent[18].src }}"
            class="d-block img-slider"
            alt="{{ propsComponent[18].alt }}"
          />
        </div>
        <div class="col-12">
          <p class="font-size-25 bolder-g text-center p">16 años</p>
          <p class="font-size-15 text-center p pb-4">acompañando los sueños de viaje de los latinoamericanos</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <img class="testimonios" src="../../assets/images/web-testimony.png" alt="testimonios" width="1" height="1" />
  <img
    class="testimonios-mobile"
    src="../../assets/images/mobile-testimony.png"
    alt="testimonios"
    width="1"
    height="1"
  />
</div>
<footer class="container-fluid footer-bg">
  <div class="container d-none d-lg-block">
    <div class="row pt-5">
      <div class="col">
        <a routerLink="/"
          ><img
            class="img-home"
            height="1"
            width="1"
            src="{{ propsComponent[1].src }}"
            alt="{{ propsComponent[1].alt }}"
        /></a>
        <div class="row d-flex mt-5">
          <span class="gris-claro font-size-15"
            >Síguenos en
            <span class="ms-4">
              <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[19].src }}"
                  alt="{{ propsComponent[19].alt }}"
                />
              </a>
              <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[20].src }}"
                  alt="{{ propsComponent[20].alt }}"
                />
              </a>
              <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[21].src }}"
                  alt="{{ propsComponent[21].alt }}"
                />
              </a> </span
          ></span>
        </div>
      </div>
      <div class="col">
        <a routerLink="../preguntas-frecuentes">
          <p class="text-white font-size-15 bolder-g">CENTRO DE AYUDA</p>
        </a>
        <a routerLink="../nosotros">
          <p class="text-white font-size-15 bolder-g">NOSOTROS</p>
        </a>
        <!--<p><a routerLink="../nosotros" class="text-white gris-claro font-size-15">¿Quiénes somos?</a></p>-->
      </div>
      <div class="col">
        <p class="text-white font-size-15 bolder-g">SERVICIOS</p>
        <p *ngIf="asesoria != ''">
          <a class="text-white gris-claro font-size-15" href="{{ asesoria }}">Asesoría Migratoria</a>
        </p>
        <p *ngIf="asesoriaEstudios != ''">
          <a class="text-white gris-claro font-size-15" href="{{ asesoriaEstudios }}"
            >Asesoría Migratoria Estudiantes</a
          >
        </p>
        <p *ngIf="firstCond">
          <a class="text-white gris-claro font-size-15" href="{{ asesoriaAmericana }}">Asesoría Visa Americana </a>
        </p>
        <p *ngIf="secondCond">
          <a class="text-white gris-claro font-size-15" href="{{ asesoriaEspana }}">Asesoría Migratoria España</a>
        </p>
        <p><a routerLink="/condiciones-uso" class="text-white gris-claro font-size-15">Condiciones de Uso</a></p>
        <p><a routerLink="/nosotros" class="text-white gris-claro font-size-15">Sobre Visa Travel Center</a></p>
      </div>
      <div class="col">
        <p class="text-white font-size-15 bolder-g">8 SEDES EN LATINOAMÉRICA</p>
        <p>
          <a class="text-white gris-claro font-size-15"
            >Colombia <br />
            <span class="font-size-12">Bogotá y Pereira </span></a
          >
        </p>
        <p>
          <a class="text-white gris-claro font-size-15"
            >Ecuador <br />
            <span class="font-size-12">Quito, Guayaquil Y Cuenca</span></a
          >
        </p>
      </div>
      <div class="col" *ngIf="texts['KUSHKI_COUNTRY'] == 'ec'">
        <p class="text-white font-size-15 bolder-g">LEGAL</p>
        <p class="text-white bolder-g font-size-13">
          Cumplimos con los permisos exigidos <br /><br />
          <span
            >ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP-
            SEVIFRAL S.A.<br /> </span
          ><br />
          <span class="font-size-15 bolder-d gris-claro" style="padding-top: 10px">Registro turístico</span><br />
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>1. La pinta E4-451 y Juan Leon Mera. </span>
          <span>1792063604001.003.9003481</span>
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>2. La pinta S/N y Av Amazonas</span>
          <span>1792063604001.008.9004558</span>
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>3. Reina Victoria N26-08 y Santa María</span>
          <span>1792063604001.010.9004022</span>
        </p>

        <a target="_blank" routerLink="../politicas-privacidad">
          <p class="text-white"><span class="font-size-15 bolder-d gris-claro"> Política de datos</span><br /></p>
        </a>
      </div>
      <div class="col" *ngIf="texts['KUSHKI_COUNTRY'] != 'ec'">
        <p class="text-white font-size-15 bolder-g">LEGAL</p>
        <p class="text-white bolder-g font-size-13">
          Cumplimos con los permisos exigidos <br />
          <span
            >ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP-
            SEVIFRAL S.A.<br /> </span
          ><br />
          <span class="font-size-15 bolder-d gris-claro">Registro turístico</span><br />
          <span>1792963604001.010.9004022</span>
        </p>
        <p class="text-white bolder-g">
          <span class="font-size-13 bolder-d gris-claro"> Permiso de funcionamiento</span><br />
          <span class="font-size-13">1792963604001.010.9004022</span>
        </p>
        <a target="_blank" routerLink="../politicas-privacidad">
          <p class="text-white"><span class="font-size-15 bolder-d gris-claro"> Política de datos</span><br /></p>
        </a>
      </div>
    </div>
    <div class="col-12">
      <hr />
    </div>
    <div class="col-12 pb-2">
      <p class="text-center gris-claro font-size-13">
        2022 All Rights Reservered. Asistencia médica con cobertura mundial.
      </p>
    </div>
  </div>
  <div class="row d-lg-none pb-5">
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-6 mt-3">
          <p class="font-size-15 text-center text-white justify-content-end align-items-end flex-colunm d-flex">
            Síguenos en
          </p>
        </div>
        <div class="col-6 mt-3">
          <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[19].src }}"
              alt="{{ propsComponent[19].alt }}"
          /></a>
          <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[20].src }}"
              alt="{{ propsComponent[20].alt }}"
          /></a>
          <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[21].src }}"
              alt="{{ propsComponent[21].alt }}"
          /></a>
        </div>
      </div>
    </div>
    <div class="col-12">
      <hr style="background-color: white" />
    </div>
    <div class="col-12">
      <p class="text-center text-white font-size-14">
        <b>Visa Travel Center - Asesoría migratoria legal </b><br /><b>Colombia:</b> Bogotá, Pereira y Medellín <br />
        <b>Ecuador:</b> Quito, Guayaquil Y Cuenca
      </p>
    </div>
    <div class="col-12 mt-3" *ngIf="texts['KUSHKI'] == 'ec'">
      <p class="text-white font-size-13 text-center">
        <b>Cumplimos con los permisos exigidos</b> <br />
        <span
          >ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP-
          SEVIFRAL S.A.<br /> </span
        ><br />
        Registro turístico <br />
        1. La pinta E4-451 y Juan Leon Mera. <br />
        1792063604001.003.9003481<br /><br />
        2. La pinta S/N y Av Amazonas <br />
        1792063604001.008.9004558<br /><br />
        3. Reina Victoria N26-08 y Santa María<br />
        1792063604001.010.9004022<br />
      </p>
    </div>

    <div class="col" *ngIf="texts['KUSHKI'] != 'ec'">
      <p class="text-white font-size-15 bolder-g">LEGAL</p>
      <p class="text-white bolder-g font-size-13">
        Cumplimos con los permisos exigidos <br />
        <span
          >ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP-
          SEVIFRAL S.A.<br /> </span
        ><br />
        <span class="font-size-15 bolder-d gris-claro">Registro turístico</span><br />
        <span>1792963604001.010.9004022</span>
      </p>
      <p class="text-white bolder-g">
        <span class="font-size-13 bolder-d gris-claro"> Permiso de funcionamiento</span><br />
        <span class="font-size-13">1792963604001.010.9004022</span>
      </p>
      <a target="_blank" routerLink="../politicas-privacidad">
        <p class="text-white"><span class="font-size-15 bolder-d gris-claro"> Política de datos</span><br /></p>
      </a>
    </div>
    <div class="col-12 mt-1">
      <a href="target_blank" a routerLink="../preguntas-frecuentes">
        <p class="text-white font-size-13 text-center decoration">Centro de ayuda</p>
      </a>
      <a href="target_blank" routerLink="../nosotros">
        <p class="text-white font-size-13 text-center decoration">Nosotros</p>
      </a>
      <a href="target_blank" routerLink="/politicas-privacidad">
        <p class="text-white font-size-13 text-center decoration">Política de datos</p>
      </a>
    </div>
    <div class="col-12 mt-3">
      <p class="text-white font-size-12 text-center">
        2019 All Rights Reservered. Asistencia médica con cobertura mundial.
      </p>
    </div>
  </div>
</footer>
