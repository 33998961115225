<footer class="container-fluid footer-bg">
  <div class="container d-none d-lg-block">
    <div class="row pt-5">
      <div class="col">
        <a routerLink="/"
          ><img
            class="img-home"
            height="1"
            width="1"
            src="{{ propsComponent[1].src }}"
            alt="{{ propsComponent[1].alt }}"
        /></a>
        <div class="row d-flex mt-5">
          <span class="gris-claro font-size-15"
            >Síguenos en
            <span class="ms-4">
              <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[19].src }}"
                  alt="{{ propsComponent[19].alt }}"
                />
              </a>
              <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[20].src }}"
                  alt="{{ propsComponent[20].alt }}"
                />
              </a>
              <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[21].src }}"
                  alt="{{ propsComponent[21].alt }}"
                />
              </a> </span
          ></span>
        </div>
      </div>
      <div class="col">
        <a routerLink="../nosotros">
          <p class="text-white font-size-15 bolder-g">NOSOTROS</p>
        </a>
        <p><a routerLink="../nosotros" class="text-white gris-claro font-size-15">¿Quiénes somos?</a></p>
      </div>
      <div class="col">
        <a routerLink="../nosotros">
          <p class="text-white font-size-15 bolder-g">SERVICIOS</p>
        </a>
        <p><a class="text-white gris-claro font-size-15">Asesorías</a></p>
        <p><a class="text-white gris-claro font-size-15">Asistencias</a></p>
        <p><a class="text-white gris-claro font-size-15">Agencias de viajes</a></p>
        <p><a class="text-white gris-claro font-size-15">Asesoría gratuita</a></p>
      </div>
      <div class="col">
        <p class="text-white font-size-15 bolder-g">8 SEDES EN LATINOAMÉRICA</p>
        <p>
          <a class="text-white gris-claro font-size-15"
            >Colombia <br />
            <span class="font-size-12">Bogotá y Pereira </span></a
          >
        </p>
        <p>
          <a class="text-white gris-claro font-size-15"
            >Ecuador <br />
            <span class="font-size-12">Quito, Guayaquil Y Cuenca</span></a
          >
        </p>
        <p>
          <a target="_blank" href="{{ map }}" class="text-white gris-claro font-size-15">
            <span
              ><img
                class="icon-map"
                height="1"
                width="1"
                src="{{ propsComponent[22].src }}"
                alt="{{ propsComponent[22].alt }}"
            /></span>
            Ver Mapa</a
          >
        </p>
      </div>
      <div class="col">
        <p class="text-white font-size-15 bolder-g">LEGAL</p>
        <p class="text-white bolder-g font-size-13">
          Cumplimos con los permisos exigidos <br /><br />
          <span class="font-size-15 bolder-d gris-claro" style="padding-top: 10px">Registro turístico</span><br />
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>1. La pinta E4-451 y Juan Leon Mera. </span>
          <span>1792063604001.003.9003481</span>
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>2. La pinta S/N y Av Amazonas</span>
          <span>1792063604001.008.9004558</span>
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>3. Reina Victoria N26-08 y Santa María</span>
          <span>1792063604001.010.9004022</span>
        </p>
        <a target="_blank" routerLink="../politicas-privacidad">
          <p class="text-white"><span class="font-size-15 bolder-d gris-claro"> Política de datos</span><br /></p>
        </a>
      </div>
    </div>
    <div class="col-12">
      <hr />
    </div>
    <div class="col-12 pb-2">
      <p class="text-center gris-claro font-size-13">
        2022 All Rights Reservered. Asistencia médica con cobertura mundial.
      </p>
    </div>
  </div>
  <div class="row d-lg-none pb-5">
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-6 mt-3">
          <p class="font-size-15 text-center text-white justify-content-end align-items-end flex-colunm d-flex">
            Síguenos en
          </p>
        </div>
        <div class="col-6 mt-3">
          <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[19].src }}"
              alt="{{ propsComponent[19].alt }}"
          /></a>
          <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[20].src }}"
              alt="{{ propsComponent[20].alt }}"
          /></a>
          <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[21].src }}"
              alt="{{ propsComponent[21].alt }}"
          /></a>
        </div>
      </div>
    </div>
    <div class="col-12">
      <hr style="background-color: white" />
    </div>
    <div class="col-12">
      <p class="text-center text-white font-size-14">
        <b>Visa Travel Center - Asesoría migratoria legal </b><br /><b>Colombia:</b> Bogotá, Pereira y Medellín <br />
        <b>Ecuador:</b> Quito, Guayaquil Y Cuenca
      </p>
    </div>
    <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
      <a
        target="_blank"
        href="https://www.google.com/maps/place/Visa+Travel+Center/@4.6791709,-74.0500153,17z/data[…]9a917dd6e2cf:0x52f74b3beb7ea651!8m2!3d4.6791709!4d-74.0478266"
        class="text-white bolder-g font-size-14 mt-3 decoration"
        ><img
          class="icon-map"
          width="1"
          height="1"
          src="{{ propsComponent[22].src }}"
          alt="{{ propsComponent[22].alt }}"
        />
        Ver ubicación mapa
      </a>
    </div>
    <div class="col-12 mt-3">
      <p class="text-white font-size-13 text-center">
        <b>Cumplimos con los permisos exigidos</b> <br />
        Registro turístico <br />
        1. La pinta E4-451 y Juan Leon Mera. <br />
        1792063604001.003.9003481<br /><br />
        2. La pinta S/N y Av Amazonas <br />
        1792063604001.008.9004558<br /><br />
        3. Reina Victoria N26-08 y Santa María<br />
        1792063604001.010.9004022<br />
      </p>
    </div>
    <div class="col-12 mt-1">
      <a href="target_blank" routerLink="/politicas-privacidad">
        <p class="text-white font-size-13 text-center decoration">Política de datos</p>
      </a>
    </div>
    <div class="col-12 mt-3">
      <p class="text-white font-size-12 text-center">
        2019 All Rights Reservered. Asistencia médica con cobertura mundial.
      </p>
    </div>
  </div>
</footer>
