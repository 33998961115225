import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  public postCustomer(url: string, body: any) {
    return this.http.post(url, body);
  }

  public postCustomerSF(body: any) {
    return this.http.post(environment.salesForceUrl, body);
  }

  public postPayment(url: string, body: any) {
    return this.http.post(url, body);
  }

  // ******** PAYU *********
  // public postIdPayment(body: any) {
  //   return this.http.post(`${environment.serverUrl}/api/services/confirmation`, body).subscribe((data) => {
  //     console.log(data);
  //   });
  // }

  public getServiceRequest(hashcode: string) {
    return this.http.get(`${environment.serverUrl}/api/services/hashcode/${hashcode}`);
  }

  public updateServiceRequest(url: string, body: any) {
    return this.http.patch(url, body);
  }

  public getServiceQuote(url: string) {
    return this.http.get(url);
  }

  public sendMB(dataMB: any, mbUrl: string) {
    return this.http.post(mbUrl, dataMB);
  }
}
