export class AppTextSettings {
  public static EC = {
    MAIN_FIRST: 'Asesorías migratorias',
    MAIN_SECOND: 'para conseguir visas',
    SECONDARY: 'Asesorías migratorias para conseguir visas',
    ADVISORY_FORM: 'Completa tus datos para obtener tu asesoría',
    MY_ADVISORY: 'COMPRAR AHORA',
    ADVISORIES_SECTION_MAIN: 'ASESORÍAS MIGRATORIAS',
    ADVISORIES_SECTION_DESC: 'Acompañamiento de nuestros expertos migratorios durante tu trámite de visa.',
    VISA_REQUIREMENTS_FIRST: 'Requisitos de',
    VISA_REQUIREMENTS_SECOND: 'visado',
    HOW_ADVISORY_FIRST: '¿Cómo funciona la',
    HOW_ADVISORY_SECOND: 'asesoría migratoria?',
    OTHERS_SECTION_MAIN: 'Aquí encuentras lo demás para tu viaje',
    ACHIEVEMENTS_SECTION_MAIN: 'VIAJES CUMPLIDOS Y OBJETIVOS ALCANZADOS',
    WE_OFFER: 'Te ofrecemos todo lo que necesitas para tu viaje',
    WS_NUMBER: '593961459059',
    KUSHKI_COUNTRY: 'ec',
    KUSHKI_DOMAIN: 'https://ec.visatravelcenter.com/',
    KUSHKI_CURRENCY: 'USD',
    MB: 'https://flows.messagebird.com/flows/165c9f3c-c641-4973-b8d8-306292ff8914/invoke',
    DEV: false,
  };
  public static PE = {
    MAIN_FIRST: 'Asesorías migratorias',
    MAIN_SECOND: 'para conseguir visas',
    SECONDARY: 'Asesorías migratorias para conseguir visas',
    ADVISORY_FORM: 'Completa tus datos para obtener tu asesoría',
    MY_ADVISORY: 'COMPRAR AHORA',
    ADVISORIES_SECTION_MAIN: 'ASESORÍAS MIGRATORIAS',
    ADVISORIES_SECTION_DESC: 'Acompañamiento de nuestros expertos migratorios durante tu trámite de visa.',
    VISA_REQUIREMENTS_FIRST: 'Requisitos de',
    VISA_REQUIREMENTS_SECOND: 'visado',
    HOW_ADVISORY_FIRST: '¿Cómo funciona la',
    HOW_ADVISORY_SECOND: 'asesoría migratoria?',
    OTHERS_SECTION_MAIN: 'Aquí encuentras lo demás para tu viaje',
    ACHIEVEMENTS_SECTION_MAIN: 'VIAJES CUMPLIDOS Y OBJETIVOS ALCANZADOS',
    WE_OFFER: 'Te ofrecemos todo lo que necesitas para tu viaje',
    WS_NUMBER: '593961459059',
    KUSHKI_COUNTRY: 'pe',
    KUSHKI_DOMAIN: 'https://pe.visatravelcenter.com/',
    KUSHKI_CURRENCY: 'USD',
    MB: '',
    DEV: false,
  };
  public static BO = {
    MAIN_FIRST: 'Asesorías migratorias',
    MAIN_SECOND: 'para conseguir visas',
    SECONDARY: 'Asesorías migratorias para conseguir visas',
    ADVISORY_FORM: 'Completa tus datos para obtener tu asesoría',
    MY_ADVISORY: 'COMPRAR AHORA',
    ADVISORIES_SECTION_MAIN: 'ASESORÍAS MIGRATORIAS',
    ADVISORIES_SECTION_DESC: 'Acompañamiento de nuestros expertos migratorios durante tu trámite de visa.',
    VISA_REQUIREMENTS_FIRST: 'Requisitos de',
    VISA_REQUIREMENTS_SECOND: 'visado',
    HOW_ADVISORY_FIRST: '¿Cómo funciona la',
    HOW_ADVISORY_SECOND: 'asesoría migratoria?',
    OTHERS_SECTION_MAIN: 'Aquí encuentras lo demás para tu viaje',
    ACHIEVEMENTS_SECTION_MAIN: 'VIAJES CUMPLIDOS Y OBJETIVOS ALCANZADOS',
    WE_OFFER: 'Te ofrecemos todo lo que necesitas para tu viaje',
    WS_NUMBER: '593961459059',
    KUSHKI_COUNTRY: 'bo',
    KUSHKI_DOMAIN: 'https://bo.visatravelcenter.com/',
    KUSHKI_CURRENCY: '',
    MB: '',
    DEV: false,
  };
  public static CO = {
    MAIN_FIRST: 'Asesorías migratorias',
    MAIN_SECOND: 'para conseguir visas',
    SECONDARY: 'Asesorías migratorias para conseguir visas',
    ADVISORY_FORM: 'Completa tus datos para obtener tu asesoría',
    MY_ADVISORY: 'COMPRAR AHORA',
    ADVISORIES_SECTION_MAIN: 'ASESORÍAS MIGRATORIAS',
    ADVISORIES_SECTION_DESC: 'Acompañamiento de nuestros expertos migratorios durante tu trámite de visa.',
    VISA_REQUIREMENTS_FIRST: 'Requisitos de',
    VISA_REQUIREMENTS_SECOND: 'visado',
    HOW_ADVISORY_FIRST: '¿Cómo funciona la',
    HOW_ADVISORY_SECOND: 'asesoría migratoria?',
    OTHERS_SECTION_MAIN: 'Aquí encuentras lo demás para tu viaje',
    ACHIEVEMENTS_SECTION_MAIN: 'VIAJES CUMPLIDOS Y OBJETIVOS ALCANZADOS',
    WE_OFFER: 'Te ofrecemos todo lo que necesitas para tu viaje',
    WS_NUMBER: '573125501225',
    KUSHKI_COUNTRY: 'co',
    KUSHKI_DOMAIN: 'https://co.visatravelcenter.com/',
    KUSHKI_CURRENCY: 'COP',
    MB: 'https://flows.messagebird.com/flows/c6b6b542-63fe-4728-8d59-71f553fb42fd/invoke',
    DEV: false,
  };
  public static VI = {
    MAIN_FIRST: 'Asesorías migratorias',
    MAIN_SECOND: 'para conseguir visas',
    SECONDARY: 'Asesorías migratorias para conseguir visas',
    ADVISORY_FORM: 'Completa tus datos para obtener tu asesoría',
    MY_ADVISORY: 'COMPRAR AHORA',
    ADVISORIES_SECTION_MAIN: 'ASESORÍAS MIGRATORIAS',
    ADVISORIES_SECTION_DESC: 'Acompañamiento de nuestros expertos migratorios durante tu trámite de visa.',
    VISA_REQUIREMENTS_FIRST: 'Requisitos de',
    VISA_REQUIREMENTS_SECOND: 'visado',
    HOW_ADVISORY_FIRST: '¿Cómo funciona la',
    HOW_ADVISORY_SECOND: 'asesoría migratoria?',
    OTHERS_SECTION_MAIN: 'Aquí encuentras lo demás para tu viaje',
    ACHIEVEMENTS_SECTION_MAIN: 'VIAJES CUMPLIDOS Y OBJETIVOS ALCANZADOS',
    WE_OFFER: 'Te ofrecemos todo lo que necesitas para tu viaje',
    WS_NUMBER: '573125501225',
    KUSHKI_COUNTRY: 'co',
    KUSHKI_DOMAIN: 'https://co.visatravelcenter.com/',
    KUSHKI_CURRENCY: 'COP',
    MB: 'https://flows.messagebird.com/flows/c6b6b542-63fe-4728-8d59-71f553fb42fd/invoke',
    DEV: false,
  };
  public static DEFAULT = {
    MAIN_FIRST: 'Asesorías migratorias',
    MAIN_SECOND: 'para conseguir visas',
    SECONDARY: 'Asesorías migratorias para conseguir visas',
    ADVISORY_FORM: 'Completa tus datos para obtener tu asesoría',
    MY_ADVISORY: 'COMPRAR AHORA',
    ADVISORIES_SECTION_MAIN: 'ASESORÍAS MIGRATORIAS',
    ADVISORIES_SECTION_DESC: 'Acompañamiento de nuestros expertos migratorios durante tu trámite de visa.',
    VISA_REQUIREMENTS_FIRST: 'Requisitos de',
    VISA_REQUIREMENTS_SECOND: 'visado',
    HOW_ADVISORY_FIRST: '¿Cómo funciona la',
    HOW_ADVISORY_SECOND: 'asesoría migratoria?',
    OTHERS_SECTION_MAIN: 'Aquí encuentras lo demás para tu viaje',
    ACHIEVEMENTS_SECTION_MAIN: 'VIAJES CUMPLIDOS Y OBJETIVOS ALCANZADOS',
    WE_OFFER: 'Te ofrecemos todo lo que necesitas para tu viaje',
    WS_NUMBER: '593961459059',
    KUSHKI_COUNTRY: 'ec',
    KUSHKI_DOMAIN: 'https://ec.visatravelcenter.com/',
    KUSHKI_CURRENCY: 'USD',
    MB: '',
    DEV: true,
  };
}
