<div class="container-fluid bg-help pb-5">
  <div class="container">
    <div class="row justify-content-center align-items-center flex-colunm d-flex">
      <div class="col-12 mt-5">
        <div class="row">
          <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light">
              <div class="container-fluid">
                <a routerLink href="">
                  <img
                    width="200"
                    src="https://res.cloudinary.com/danfer13/image/upload/v1658945081/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Frame_puqtrt.webp"
                    alt=""
                  />
                </a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent" style="width: 100%; color: #484848">
                  <ul class="navbar-nav mb-2 mb-lg-0" style="margin-left: auto; text-align: right">
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Servicios
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                          <a class="dropdown-item" (click)="redirectTo('/centro-ayuda/servicios')"
                            >Asesorías migratorias</a
                          >
                        </li>
                        <li><hr class="dropdown-divider" /></li>
                        <li>
                          <a class="dropdown-item" (click)="redirectTo('/centro-ayuda/servicios')"
                            >Asistencias médicas</a
                          >
                        </li>
                        <li><hr class="dropdown-divider" /></li>
                        <li>
                          <a class="dropdown-item" (click)="redirectTo('/centro-ayuda/servicios')">Agencia de viajes</a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" (click)="redirectTo('/centro-ayuda/estudios-de-perfil')">Estudio de perfil</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" routerLink="/nosotros">Sobre nosotros</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" (click)="redirectTo('/centro-ayuda/contacto')">Contacto</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" routerLink="/preguntas-frecuentes">Centro de ayuda</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7 mt-5">
        <a class="gray" routerLink="">Inicio </a>
      </div>
      <div class="col-12 col-lg-7 mt-1 mt-lg-3">
        <a routerLink="/preguntas-frecuentes" class="font-size-30 font-size-lg-40 gray bolder">Centro de ayuda</a>
      </div>
      <div class="col-12 col-lg-7 mt-3" *ngIf="currentHelp === 'servicios'">
        <h1 class="font-size-20 gray bolder-g">Servicios de Visa Travel Center</h1>
        <span class="bolder-d font-size-14">Aquí puedes encontrar todo para tu viaje</span>
      </div>
      <div class="col-12 col-lg-7 mt-3" *ngIf="currentHelp === 'citas'">
        <h1 class="font-size-20 gray bolder-g">Asignación de una cita de visa</h1>
      </div>
      <div class="col-12 col-lg-7 mt-3" *ngIf="currentHelp === 'justificacion-economica'">
        <h1 class="font-size-20 gray bolder-g">Justificación económica para visados</h1>
      </div>
      <div class="col-12 col-lg-7 mt-3" *ngIf="currentHelp === 'estudios-de-perfil'">
        <h1 class="font-size-20 gray bolder-g">Estudio de perfil para visas</h1>
      </div>
      <div class="col-12 col-lg-7 mt-3" *ngIf="currentHelp === 'requisitos-visas'">
        <h1 class="font-size-20 gray bolder-g">Requisitos para visas</h1>
      </div>

      <div class="col-12 col-lg-7 mt-3" *ngIf="currentHelp === 'contacto'">
        <div class="row">
          <div class="col-12">
            <h1 class="font-size-22 gray bolder-g">Canales de contacto</h1>
            <span>Aquí puedes encontrar todo para tu viaje</span>
          </div>
          <div class="col-12 mt-5">
            Puedes contactarnos a través de: <br />
            📞 WhatsApp: +{{ this.texts['WS_NUMBER'] }} <br />
            📧 Correo electrónico: ventas@visatravelcenter.com
          </div>
          <!-- <div class="col-12 mt-4">
            <span
              ><img
                width="25"
                src="https://res.cloudinary.com/danfer13/image/upload/v1659048941/VTC%20-%20IMAGES/help-center/Canales%20de%20contacto/Vector_xypdcu.webp"
                alt=""
            /></span>
            <span class="text-danger bolder-g font-size-14 font-size-lg-18 ms-2">ventas@visatravelcenter.com </span>
          </div> -->
        </div>

        <div class="col-12 mt-5">
          <h2 class="font-size-22 gray bolder-g">📲 Redes sociales de Visa Travel Center</h2>
        </div>

        <div class="row mt-5">
          <div class="col-12 col-lg-4 pb-3">
            <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
              <span
                ><img
                  width="25"
                  src="https://res.cloudinary.com/danfer13/image/upload/v1659048941/VTC%20-%20IMAGES/help-center/Canales%20de%20contacto/XMLID-3_tpyyf3.webp"
                  alt=""
              /></span>
              <span class="text-danger bolder-g font-size-16 ms-2">@visatravelcenter</span>
            </a>
          </div>

          <div class="col-12 col-lg-4 pb-3">
            <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
              <span
                ><img
                  width="25"
                  src="https://res.cloudinary.com/danfer13/image/upload/v1659048941/VTC%20-%20IMAGES/help-center/Canales%20de%20contacto/XMLID-7_spylvx.webp"
                  alt=""
              /></span>
              <span class="text-danger bolder-g font-size-16 ms-2">Visa Travel Center</span>
            </a>
          </div>

          <div class="col-12 col-lg-4 pb-3">
            <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
              <span
                ><img
                  width="25"
                  src="https://res.cloudinary.com/danfer13/image/upload/v1659048941/VTC%20-%20IMAGES/help-center/Canales%20de%20contacto/Tik-tok_whfnye.webp"
                  alt=""
              /></span>
              <span class="text-danger bolder-g font-size-16 ms-2">Visa Travel Center</span>
            </a>
          </div>
        </div>

        <div class="col-12 col-lg-7 mt-5">
          <h2 class="font-size-22 gray bolder-g pb-3">Contáctanos ahora</h2>
          <span class="font-size-14 font-size-lg-16"
            >Una vez que adquieras uno de nuestros servicios, tendrás comunicación directa con tu asesor.
          </span>
        </div>
      </div>

      <div class="col-12 col-lg-7 mt-3" *ngIf="currentHelp === 'default'">
        <h3 class="font-size-20 gray bolder-g"><b>No se encontro informacion sobre esta categoria</b></h3>
      </div>
      <div class="col-12 col-lg-7 mt-3 mt-lg-5">
        <div class="row" *ngFor="let category of help_center">
          <div
            class="col-12 border mt-1 mt-lg-3 accordion"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="category.collapse_id"
            aria-expanded="true"
            [attr.aria-controls]="category.collapse"
            (click)="toggleArrow(category.id)"
          >
            <div class="row p-2 accordion-header" id="{{ category.heading }}">
              <div class="col-1 justify-content-center align-items-center flex-colunm d-flex">
                <img width="35" src="{{ category.icon }}" alt="" />
              </div>
              <div class="col-10 justify-content-start align-items-center flex-colunm d-flex">
                <h2 class="text-danger bolder-g font-size-14 font-size-lg-16 mb-0" [innerHTML]="category.name"></h2>
              </div>
              <div class="col-1 justify-content-start align-items-center flex-colunm d-flex">
                <img
                  [ngClass]="category.transform ? 'btn-toggle-active' : 'btn-toggle'"
                  width="15"
                  src="{{ category.arrow }}"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="accordion" id="accordionExample" *ngIf="category.subcategory != false">
            <div class="row">
              <div
                id="{{ category.collapse }}"
                class="accordion-collapse collapse"
                [attr.aria-labelledby]="category.heading"
                data-bs-parent="#accordionExample"
              >
                <!-- Accordion Category -->
                <div class="accordion" id="accordionExample3">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="{{ category.heading }}"></h2>
                    <div
                      id="{{ category.collapse }}"
                      class="accordion-collapse collapse"
                      [attr.aria-labelledby]="category.heading"
                      data-bs-parent="#accordionExample3"
                    >
                      <div class="accordion-body font-size-14 font-size-lg-16">
                        <p [innerHTML]="category.body"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Accordion Category -->

                <div class="mt-3" *ngFor="let subcategory of category.subcategory">
                  <div class="col-12 border mt-1 mt-lg-3">
                    <div class="row h-55px">
                      <div class="col-2 col-lg-1 justify-content-center align-items-center flex-colunm d-flex">
                        <img
                          id="{{ subcategory.id_image }}"
                          [ngClass]="subcategory.transform ? 'btn-toggle-active' : 'btn-toggle'"
                          width="15"
                          src="{{ category.arrow }}"
                          alt=""
                        />
                      </div>
                      <div class="col-10 col-lg-11 justify-content-start align-items-center flex-colunm d-flex">
                        <h3
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="subcategory.collapse_id"
                          aria-expanded="true"
                          [attr.aria-controls]="subcategory.collapse"
                          class="bolder-g gray font-size-14 font-size-lg-16"
                          (click)="toggleArrowSub(category.id, subcategory.id)"
                        >
                          {{ subcategory.name }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="accordion" id="accordionExample2">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="{{ subcategory.heading }}"></h2>
                      <div
                        id="{{ subcategory.collapse }}"
                        class="accordion-collapse collapse"
                        [attr.aria-labelledby]="subcategory.heading"
                        data-bs-parent="#accordionExample2"
                      >
                        <div class="accordion-body font-size-14 font-size-lg-16">
                          <p [innerHTML]="subcategory.body"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Else if -->
          <div class="accordion" id="accordionExample4" *ngIf="category.subcategory == false">
            <div class="accordion-item">
              <h2 class="accordion-header" id="{{ category.heading }}"></h2>
              <div
                id="{{ category.collapse }}"
                class="accordion-collapse collapse"
                [attr.aria-labelledby]="category.heading"
                data-bs-parent="#accordionExample4"
              >
                <div class="accordion-body font-size-14 font-size-lg-16">
                  <p [innerHTML]="category.body"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer class="container-fluid footer-bg mt-5">
  <div class="container d-none d-lg-block">
    <div class="row pt-5">
      <div class="col">
        <a routerLink="/"
          ><img
            class="img-home"
            height="1"
            width="1"
            src="{{ propsComponent[0].src }}"
            alt="{{ propsComponent[0].alt }}"
        /></a>
        <div class="row d-flex mt-5">
          <span class="gris-claro font-size-15"
            >Síguenos en
            <span class="ms-4">
              <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[1].src }}"
                  alt="{{ propsComponent[1].alt }}"
                />
              </a>
              <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[2].src }}"
                  alt="{{ propsComponent[2].alt }}"
                />
              </a>
              <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
                <img
                  width="1"
                  height="1"
                  class="red-social"
                  src="{{ propsComponent[3].src }}"
                  alt="{{ propsComponent[3].alt }}"
                />
              </a> </span
          ></span>
        </div>
      </div>
      <div class="col">
        <a (click)="redirectFAQ()">
          <p class="text-white font-size-15 bolder-g">CENTRO DE AYUDA</p>
        </a>
        <a (click)="redirectUs()">
          <p class="text-white font-size-15 bolder-g">NOSOTROS</p>
        </a>
      </div>
      <div class="col">
        <p class="text-white font-size-15 bolder-g">SERVICIOS</p>
        <p *ngIf="asesoria != ''">
          <a class="text-white gris-claro font-size-15" href="{{ asesoria }}">Asesoría Migratoria</a>
        </p>
        <p *ngIf="asesoriaEstudios != ''">
          <a class="text-white gris-claro font-size-15" href="{{ asesoriaEstudios }}"
            >Asesoría Migratoria Estudiantes</a
          >
        </p>
        <p *ngIf="firstCond">
          <a class="text-white gris-claro font-size-15" href="{{ asesoriaAmericana }}">Asesoría Visa Americana </a>
        </p>
        <p *ngIf="secondCond">
          <a class="text-white gris-claro font-size-15" href="{{ asesoriaEspana }}">Asesoría Migratoria España</a>
        </p>
        <p><a routerLink="/condiciones-uso" class="text-white gris-claro font-size-15">Condiciones de Uso</a></p>
        <p><a routerLink="/nosotros" class="text-white gris-claro font-size-15">Sobre Visa Travel Center</a></p>
      </div>
      <div class="col">
        <p class="text-white font-size-15 bolder-g">8 SEDES EN LATINOAMÉRICA</p>
        <p>
          <a class="text-white gris-claro font-size-15"
            >Colombia <br />
            <span class="font-size-12">Bogotá y Pereira </span></a
          >
        </p>
        <p>
          <a class="text-white gris-claro font-size-15"
            >Ecuador <br />
            <span class="font-size-12">Quito, Guayaquil Y Cuenca</span></a
          >
        </p>
        <p>
          <a
            target="_blank"
            href="https://www.google.com/maps/place/Visa+Travel+Center/@4.6791709,-74.0500153,17z/data[…]9a917dd6e2cf:0x52f74b3beb7ea651!8m2!3d4.6791709!4d-74.0478266"
            class="text-white gris-claro font-size-15"
          >
            <span
              ><img
                class="icon-map"
                height="1"
                width="1"
                src="{{ propsComponent[4].src }}"
                alt="{{ propsComponent[4].alt }}"
            /></span>
            Ver Mapa</a
          >
        </p>
      </div>
      <div class="col">
        <p class="text-white font-size-15 bolder-g">LEGAL</p>
        <p class="text-white bolder-g font-size-13">
          Cumplimos con los permisos exigidos <br /><br />
          <span class="font-size-15 bolder-d gris-claro" style="padding-top: 10px">Registro turístico</span><br />
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>1. La pinta E4-451 y Juan Leon Mera. </span>
          <span>1792063604001.003.9003481</span>
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>2. La pinta S/N y Av Amazonas</span>
          <span>1792063604001.008.9004558</span>
        </p>
        <p class="text-white bolder-g font-size-12">
          <span>3. Reina Victoria N26-08 y Santa María</span>
          <span>1792063604001.010.9004022</span>
        </p>

        <a target="_blank" routerLink="../politicas-privacidad">
          <p class="text-white"><span class="font-size-15 bolder-d gris-claro"> Política de datos</span><br /></p>
        </a>
      </div>
    </div>
    <div class="col-12">
      <hr />
    </div>
    <div class="col-12 pb-2">
      <p class="text-center gris-claro font-size-13">
        2022 All Rights Reservered. Asistencia médica con cobertura mundial.
      </p>
    </div>
  </div>
  <div class="row d-lg-none pb-5">
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-6 mt-3">
          <p class="font-size-15 text-center text-white justify-content-end align-items-end flex-colunm d-flex">
            Síguenos en
          </p>
        </div>
        <div class="col-6 mt-3">
          <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[1].src }}"
              alt="{{ propsComponent[1].alt }}"
          /></a>
          <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[2].src }}"
              alt="{{ propsComponent[2].alt }}"
          /></a>
          <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
            <img
              width="1"
              height="1"
              class="red-social"
              src="{{ propsComponent[3].src }}"
              alt="{{ propsComponent[3].alt }}"
          /></a>
        </div>
      </div>
    </div>
    <div class="col-12">
      <hr style="background-color: white" />
    </div>
    <div class="col-12">
      <p class="text-center text-white font-size-14">
        <b>Visa Travel Center - Asesoría migratoria legal </b><br /><b>Colombia:</b> Bogotá, Pereira y Medellín <br />
        <b>Ecuador:</b> Quito, Guayaquil Y Cuenca
      </p>
    </div>
    <div class="col-12 justify-content-center align-items-center flex-colunm d-flex">
      <a
        target="_blank"
        href="https://www.google.com/maps/place/Visa+Travel+Center/@4.6791709,-74.0500153,17z/data[…]9a917dd6e2cf:0x52f74b3beb7ea651!8m2!3d4.6791709!4d-74.0478266"
        class="text-white bolder-g font-size-14 mt-3 decoration"
        ><img
          class="icon-map"
          width="1"
          height="1"
          src="{{ propsComponent[4].src }}"
          alt="{{ propsComponent[4].alt }}"
        />
        Ver ubicación mapa
      </a>
    </div>
    <div class="col-12 mt-3">
      <p class="text-white font-size-13 text-center">
        <b>Cumplimos con los permisos exigidos</b> <br />
        <span
          >ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP-
          SEVIFRAL S.A.<br /> </span
        ><br />
        Registro turístico <br />
        1. La pinta E4-451 y Juan Leon Mera. <br />
        1792063604001.003.9003481<br /><br />
        2. La pinta S/N y Av Amazonas <br />
        1792063604001.008.9004558<br /><br />
        3. Reina Victoria N26-08 y Santa María<br />
        1792063604001.010.9004022<br />
      </p>
    </div>
    <div class="col-12 mt-1">
      <a (click)="redirectFAQ()">
        <p class="text-white font-size-13 text-center decoration">Centro de ayuda</p>
      </a>
      <a (click)="redirectUs()">
        <p class="text-white font-size-13 text-center decoration">Nosotros</p>
      </a>
      <a (click)="redirectPrivacy()">
        <p class="text-white font-size-13 text-center decoration">Política de datos</p>
      </a>
    </div>
    <div class="col-12 mt-3">
      <p class="text-white font-size-12 text-center">
        2019 All Rights Reservered. Asistencia médica con cobertura mundial.
      </p>
    </div>
  </div>
</footer>
