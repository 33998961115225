export class HelpCenterInfo {
  public static SERVICES = [
    {
      id: 1,
      name: '1. Asesorías migratorias para visados',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1658944654/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/1_d8ivmn.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: [
        {
          id: 1,
          id_image: 'img1',
          name: 'Lo que hacemos por ti en la asesoria',
          collapse: 'collapsesub1',
          heading: 'headingsub1',
          collapse_id: '#collapsesub1',
          transform: '',
          body: '✅ Preparar una carpeta revisada por asesores expertos del área de solicitud visados<br>✅ Agendar la cita de visado en el momento correcto<br>✅ Llenar los formularios y requisitos de la mano de expertos<br>✅ Ofrecerte una agencia donde conseguir todo para tu viaje<br>✅ Ayudarte a reunir los requisitos<br><br><strong>Nota:</strong> nuestro servicio es netamente de asesoría al interesado, sin embargo, podemos tratar casos especiales en los que con la asignación de un respectivo poder de representación, nos encargamos de presentar al solicitante ante el Consulado o Embajada. ',
        },
        {
          id: 2,
          id_image: 'img2',
          name: 'Garantia de aprobación de visados',
          collapse: 'collapsesub2',
          heading: 'headingsub2',
          collapse_id: '#collapsesub2',
          transform: '',
          body: 'Queremos que tengas la mejor experiencia en tu trámite de visado con nosotros, y buscamos tanto como tú, que cumplas con tu plan de viaje. Por ello, nuestro equipo de asesores legales se encargan de aumentar tu porcentaje de aprobación.<br><br>Por ejemplo, nuestro índice de aprobación de visas a Estados Unidos es de 98%. Es decir, el 98% de las solicitudesde visa en las que hemos asesorado a nuestros clientes, han sido aprobadas con éxito. Sin embargo, debemos tener en cuenta que la decisión final la tiene la Embajada y no podemos influir en esta.',
        },
        {
          id: 3,
          id_image: 'img3',
          name: 'Experticia de nuestros asesores',
          collapse: 'collapsesub3',
          heading: 'headingsub3',
          collapse_id: '#collapsesub3',
          transform: '',
          body: 'Todos nuestros asesores se fundamentan en los requisitos exigidos por el Consulado o Embajada responsable del proceso, y adicionalmente solicitan documentos basados en su experiencia como expertos migratorios.',
        },
        {
          id: 4,
          id_image: 'img4',
          name: 'Lo que sigue después de comprar una asesoría migratoria',
          collapse: 'collapsesub4',
          heading: 'headingsub4',
          collapse_id: '#collapsesub4',
          transform: '',
          body: 'Una vez hayas comprado tu servicio, tu asesor se pondrá en contacto contigo vía email para agendar la primera reunión de carácter migratorio.',
        },
        {
          id: 5,
          id_image: 'img5',
          name: 'Documentos requeridos para la solicitud de visado',
          collapse: 'collapsesub5',
          heading: 'headingsub5',
          collapse_id: '#collapsesub5',
          transform: '',
          body: 'En la primera videollamada que tendrás con el asesor legal, una vez hayas adquirido tu servicio, se indicarán las pautas del proceso a seguir y los requisitos necesarios.<br><br>Estos se enviarán por nuestros medios de comunicación y siempre estarán disponibles para que puedas validar el archivo de requisitos en todo momento.',
        },
        {
          id: 6,
          id_image: 'img6',
          name: 'Tiempo de revisión de documentos',
          collapse: 'collapsesub6',
          heading: 'headingsub6',
          collapse_id: '#collapsesub6',
          transform: '',
          body: 'Una vez que envíes los documentos requeridos, posterior a tu primera reunión, un asesor de Visa Travel Center puede demorar en revisarlos entre 12 y 24 horas. Mientras que los Consulados pueden tardar hasta 20 días hábiles.',
        },
        {
          id: 7,
          id_image: 'img7',
          name: 'Cantidad de reuniones incluidas en la asesoría migratoria',
          collapse: 'collapsesub7',
          heading: 'headingsub7',
          collapse_id: '#collapsesub7',
          transform: '',
          body: 'Esto dependerá del paquete de asesoría que adquieras. Sin embargo, en todos los paquetes siempre estarás recibiendo acompañamiento de nuestro equipo por medio del chat.',
        },
      ],
      heading: 'heading1',
      collapse: 'collapse1',
      collapse_id: '#collapse1',
      transform: '',
      body: 'a. Recibirás un asesor legal que responderá todas tus dudas <br>b. Te ayudará a reunir los documentos y a llenar formularios<br> c. Te dará los consejos para que logres una entrevista exitosa<br>d. Celebrará contigo la aprobación de tu visa',
    },
    {
      id: 2,
      name: '2. Asesorías notariales',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1658944654/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/2_gfjduk.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: '',
      heading: 'heading2',
      collapse: 'collapse2',
      collapse_id: '#collapse2',
      transform: '',
      body: 'Es un servicio para ayudar a las personas a obtener de forma sencilla algún documento legal, relacionado con un trámite de viaje o de otro tipo de proceso que amerite la aprobación de una notaría pública.',
    },
    {
      id: 3,
      name: '3. Agencia de viajes',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1658944654/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/3_rn6j52.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading3',
      collapse: 'collapse3',
      collapse_id: '#collapse3',
      transform: '',
      body: 'Tenemos paquetes turísticos y boletos aéreos a cualquier parte del mundo.',
    },
    {
      id: 4,
      name: '4. Asistencias médicas',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1658944655/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/4_tpebpt.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading4',
      collapse: 'collapse4',
      collapse_id: '#collapse4',
      transform: '',
      body: 'En Visa Travel Center contamos con asistencias médicas válidas en cualquier país, incluyendo los países que conforman el Espacio Schengen, además contamos con seguros médicos para viajes de larga estancia.<br>Nuestros planes incluyen muchos beneficios, entre ellos:<br><br>✅ Entre 30.000$  y 100.000$ de cobertura<br>✅ Atención médica por accidente o enfermedad<br>✅ Atención médica por COVID-19<br>✅ Odontología de urgencias<br>✅ Atención psicológica vía telefónica<br><br>¡Y muchos más! Para más información, escríbenos a cualquiera de nuestros canales de contacto.',
    },
    {
      id: 5,
      name: '5. Estudios de perfil para visados',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1658944655/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/5_pwxwdh.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading5',
      collapse: 'collapse5',
      collapse_id: '#collapse5',
      transform: '',
      body: 'El estudio de perfil te permite conocer las probabilidades que tienes de optar por la visa que deseas. Consiste en una revisión profunda de tu perfil para la preparación de un plan de viaje, realizada por un asesor migratorio. El plan de viaje incluye asesorías, fechas de viaje y postulación del visado.<br><br><strong>Nota:</strong> podemos llevar tu proceso de visado de manera online, solo será necesario que te acerques a alguna de nuestras oficinas antes de la cita de visado. Sin embargo, para visas a Canadá y USA podemos ayudarte con tu visa de forma online.',
    },
  ];

  public static QUOTES = [
    {
      id: 1,
      name: 'Tiempo para solicitar la asignación de una cita en la Embajada ',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1658944654/VTC%20-%20IMAGES/help-center/Asignacion%20de%20cita/1_sz2yoo.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      heading: 'heading2',
      collapse: 'collapse2',
      collapse_id: '#collapse2',
      subcategory: '',
      body: 'Es importante que sepas que cuando se trata de lapsos de tiempo para solicitar y agendar una cita en la Embajada correspondiente a tu caso, todo dependerá de cada autoridad consular y de la disponibilidad que esta tenga.<br><br> Nosotros nos encargamos de realizar seguimiento para solicitar, agendar y reservar citas cuanto antes, para que puedas presentarte.  Esto dependerá tanto del país al que desees solicitar la visa, como de tu perfil y los requisitos que necesites para el visado.',
    },
    {
      id: 2,
      name: '¿Cómo hacer que la fecha de tu cita de visado esté acorde con tu itinerario de viaje?',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659048249/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-8_xenmzo.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading3',
      collapse: 'collapse3',
      collapse_id: '#collapse3',
      transform: false,
      body: 'El sistema es muy impredecible y dependemos de la disponibilidad de agenda de la Embajada, por lo que te recomendamos comenzar con tu proceso migratorio 6 meses antes de tu fecha de viaje. Contamos con un equipo encargado de actualizar la información de las citas constantemente, y de ubicar la mejor fecha posible para nuestros clientes.',
    },
    {
      id: 3,
      name: '¿Cuánto demora la asignación de una cita?',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659048249/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-7_zxgc80.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading4',
      collapse: 'collapse4',
      collapse_id: '#collapse4',
      transform: false,
      body: 'Podemos demorarnos máximo 30 días en asignarte una cita, esto depende de la agenda que brinde el Consulado o Embajada correspondiente.',
    },
  ];

  public static ECONOMIC = [
    {
      id: 1,
      name: 'Medios económicos para obtener una visa de turismo',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659048600/VTC%20-%20IMAGES/help-center/Justificacion%20economica/2_o61bbg.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      heading: 'heading2',
      collapse: 'collapse2',
      collapse_id: '#collapse2',
      subcategory: '',
      body: 'Para una estadía de turismo regular (de 15 a 30 días) se debe manejar un mínimo de 4.500 USD disponibles entre cuentas bancarias, cooperativas, tarjetas de créditos, pólizas a plazo fijo e inversiones.',
    },
    {
      id: 2,
      name: '¿En qué consiste el valor consular?',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1658944655/VTC%20-%20IMAGES/help-center/Justificacion%20economica/1_h62zka.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading3',
      collapse: 'collapse3',
      collapse_id: '#collapse3',
      transform: false,
      body: 'El valor consular es el costo de la visa. Debe ser pagado directamente en el Consulado/Embajada por la persona solicitante del visado.',
    },
    {
      id: 3,
      name: 'Monto mínimo requerido para estudios en el extranjero ',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659048600/VTC%20-%20IMAGES/help-center/Justificacion%20economica/3_lbdovf.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading4',
      collapse: 'collapse4',
      collapse_id: '#collapse4',
      transform: false,
      body: 'El monto mínimo requerido para estudiar en el extranjero depende de muchas variables, entre ellas el tiempo de viaje. Sin embargo, hay un promedio por mes de 600 USD, más los gastos de la universidad o institución.',
    },
  ];

  public static STUDIES = [
    {
      id: 1,
      name: '¿Qué es el estudio de perfil para visas? ',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659043211/VTC%20-%20IMAGES/help-center/Estudio%20de%20perfil/icon-study_ee0fc6.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      heading: 'heading2',
      collapse: 'collapse2',
      collapse_id: '#collapse2',
      subcategory: '',
      body: 'Es una reunión directa con un asesor legal especializado para evaluar tu perfil y sugerir mejoras, con el objetivo de aumentar las probabilidades de obtener el visado requerido.',
    },
    {
      id: 2,
      name: 'Beneficios de la evaluación de perfil para visas ',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659043211/VTC%20-%20IMAGES/help-center/Estudio%20de%20perfil/icon-study_ee0fc6.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      heading: 'heading3',
      collapse: 'collapse3',
      collapse_id: '#collapse3',
      subcategory: '',
      body: '✅ Conocer tus posibilidades de aprobación de visa <br><br> ✅ Obtener consejos para mejorar tu perfil <br><br>  ✅ Ahorrar tiempo y dinero en trámites innecesarios o no viables',
    },
  ];

  public static REQUIREMENTS = [
    {
      id: 1,
      name: 'Requisitos para una visa de estudios',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659043910/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-1_wxgbzw.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading1',
      collapse: 'collapse1',
      collapse_id: '#collapse1',
      transform: '',
      body: '✈ Tener la carta de aceptación al centro de estudios  <br> ✈   Contar con medios económicos demostrables y justificables <br> ✈ Tener un seguro de viaje o de salud aceptado en el país de destino <br><br> Nota: no es recomendable comprar tickets y estadías antes de obtener la visa.',
    },
    {
      id: 2,
      name: 'Esto necesitas para una visa de trabajo',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659048441/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-6_xmozxv.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading2',
      collapse: 'collapse2',
      collapse_id: '#collapse2',
      transform: '',
      body: '✈ Un contrato de trabajo registrado en la nación hacia donde se viaja <br>  ✈ La autorización de residencia dada por migraciones o un órgano encargado <br><br> Nota: no necesitas comprar boletos aéreos ni estadías antes de recibir la visa.',
    },
    {
      id: 3,
      name: 'Lista de lo requerido para una visa de turismo',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659044974/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-3_dp44e3.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading3',
      collapse: 'collapse3',
      collapse_id: '#collapse3',
      transform: '',
      body: '✈ Medios económicos demostrables y justificables <br>  ✈ Justificación de la profesión actual <br>  ✈ Itinerario de viaje mediante reservas de vuelo u hotel  <br>  ✈ Seguro de viaje  <br><br>   Nota: no es necesario  comprar pasajes ni  servicio de hospedaje antes de conseguir el visado de turismo.',
    },
    {
      id: 4,
      name: '¿Cómo es más recomendable pedir una visa de turismo? ¿Solo o en familia? ',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659044974/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-4_spf0kh.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading4',
      collapse: 'collapse4',
      collapse_id: '#collapse4',
      transform: '',
      body: 'Siempre es más creíble el motivo de viaje de turismo cuando viaja el núcleo familiar, normalmente está conformado por padres, hijos, abuelos, primos y tíos. <br><br> En la mayoría de los casos aplican solo padres e hijos, esto es lo más recomendable para un visado de turismo.',
    },
    {
      id: 5,
      name: 'Tasas consulares del Espacio Schengen',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659044974/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-5_qmsljc.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading5',
      collapse: 'collapse5',
      collapse_id: '#collapse5',
      transform: '',
      body: 'Una tasa consular es el costo que tiene una visa. <br><br>  Existen intervalos de edades para determinar cuánto paga cada solicitante. <br><br>  Los menores de 6 años no pagan nada, aunque a los niños que tienen entre 6 y 12 años se les exige una tarifa reducida al 50%, al igual que a adultos mayores de 75 años. <br><br>  Las tasas consulares en el caso del visado Schengen varían, sin embargo, oscilan normalmente entre 105 USD y 150 USD. Es necesario resaltar que esta tarifa siempre se debe cancelar en efectivo.',
    },
    {
      id: 6,
      name: 'Vigencia del pasaporte',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659048441/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-6_xmozxv.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading6',
      collapse: 'collapse6',
      collapse_id: '#collapse6',
      transform: '',
      body: 'El pasaporte del solicitante de la visa debe tener una vigencia mínima de 6 meses después del retorno del futuro viaje a realizar.',
    },
    {
      id: 7,
      name: 'Tiempo de viaje',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659044974/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-4_spf0kh.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading7',
      collapse: 'collapse7',
      collapse_id: '#collapse7',
      transform: '',
      body: 'Se debe hacer la solicitud por el tiempo requerido,  con base en eso el Consulado o Embajada autorizará el tiempo para viajar y la vigencia que tendrá la visa.',
    },
    {
      id: 8,
      name: '¿Qué debo hacer si me niegan la visa?',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659048441/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-6_xmozxv.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading8',
      collapse: 'collapse8',
      collapse_id: '#collapse8',
      transform: '',
      body: 'En el caso en el que tu solicitud de visa sea negada, puedes apelar con nueva evidencia, o fortaleciendo la misma información presentada. <br><br> Es importante identificar cuáles fueron las debilidades en tus requisitos, para que luego puedas disfrutar de una aprobación.  ',
    },
    {
      id: 9,
      name: '¿Debo tener tickets aéreos y reservas de hotel comprados para solicitar la visa?',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659048441/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-6_xmozxv.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading9',
      collapse: 'collapse9',
      collapse_id: '#collapse9',
      transform: '',
      body: 'La respuesta es no. No necesitas ni es recomendable comprar tickets y estadías antes de obtener la visa.',
    },
    {
      id: 10,
      name: 'Tiempo de antelación para solicitar el visado Schengen',
      icon: 'https://res.cloudinary.com/danfer13/image/upload/v1659044974/VTC%20-%20IMAGES/help-center/Requisitos%20para%20visa/icon-3_dp44e3.webp',
      arrow:
        'https://res.cloudinary.com/danfer13/image/upload/v1658946306/VTC%20-%20IMAGES/help-center/Centro%20de%20ayuda/Group-204_os3f0y.webp',
      subcategory: false,
      heading: 'heading10',
      collapse: 'collapse10',
      collapse_id: '#collapse10',
      transform: '',
      body: 'Puedes solicitar tu visado Schengen a partir de 6 meses antes de tu fecha de viaje estimada.',
    },
  ];

  public static DEFAULT = [];

  // properties html image
  public static PROPSCOMPONENT = [
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657561543/VTC%20-%20IMAGES/VTC_svckam_rzp8ul.webp',
      alt: 'Logo Visa Travel Center',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040006/VTC%20-%20IMAGES/facebook_yunfqs.svg',
      alt: 'Icon Facebook',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/instagram_okrkez.svg',
      alt: 'Icon Instagram',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040010/VTC%20-%20IMAGES/tiktok_hwcttx.svg',
      alt: 'Icon Tiktok',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/mapa_bmclt7.svg',
      alt: 'Icon Mapa',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1658426312/VTC%20-%20IMAGES/faq/fecha_itfrtw.webp',
      alt: 'Ico Flecha',
    },
  ];
}
