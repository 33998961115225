import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  // properties html image
  propsComponent: any = [
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657561382/VTC%20-%20IMAGES/Cerrar_e730wu_xfr4nm.webp',
      alt: 'Boton Cerrar',
      title: 'Boton Cerrar',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657561543/VTC%20-%20IMAGES/VTC_svckam_rzp8ul.webp',
      alt: 'Logo Visa Travel Center',
    },
    {
      wid: '100px',
      heig: '100px',
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563111/VTC%20-%20IMAGES/asesoria-migratoria_znznz3_mxfefc.webp',
      alt: 'Asesorias migratorias',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563041/VTC%20-%20IMAGES/pasaporte_sy6f5j_t2ledd.webp',
      alt: 'Pasaporte vigente',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563209/VTC%20-%20IMAGES/seguro_fc1wfy-_1__enpu2s.webp',
      alt: 'Seguro de viaje',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563260/VTC%20-%20IMAGES/justificacion_onikqd_uk8l5p.webp',
      alt: 'Justificacion economica',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563315/VTC%20-%20IMAGES/reserva_exvkyx_lienrz.webp',
      alt: 'Reserva de hotel y vuelo',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563741/VTC%20-%20IMAGES/am7vh-2u6sy_nvfwnw.webp',
      alt: 'Barra de numeros',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563784/VTC%20-%20IMAGES/a56gr-bukuz_zui8km.webp',
      alt: 'Asistencias medicas',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563822/VTC%20-%20IMAGES/a2ung-ltqge_xuus2r.webp',
      alt: 'Agencia de viaje',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040005/VTC%20-%20IMAGES/estudio_ccugh6.png',
      alt: 'Estudio de perfil para visado',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657564045/VTC%20-%20IMAGES/a2z23-75mi9_hc2b0y.webp', // 11
      alt: 'Boletos aereos',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040009/VTC%20-%20IMAGES/Passport_i2wcmv.svg',
      alt: 'Icon Pasaporte',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040006/VTC%20-%20IMAGES/banderas-1_ciludr.svg',
      alt: 'Banderas Pasaporte',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040005/VTC%20-%20IMAGES/documentos_cswkzi.svg',
      alt: 'Icon Visas aprobadas',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657039999/VTC%20-%20IMAGES/banderas-2_uwocxq.svg',
      alt: 'Bandera Visas aprobadas',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040011/VTC%20-%20IMAGES/Viajero_ewfops.svg',
      alt: 'Icon Viajero',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/Mundo_opfm16.svg',
      alt: 'Icon Mundo - Visas aprobadas',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040005/VTC%20-%20IMAGES/estrella_vribq5.svg', //18
      alt: 'Icon Estrella - Sueño de viajeros',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040006/VTC%20-%20IMAGES/facebook_yunfqs.svg',
      alt: 'Icon Facebook',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/instagram_okrkez.svg',
      alt: 'Icon Instagram',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040010/VTC%20-%20IMAGES/tiktok_hwcttx.svg',
      alt: 'Icon Tiktok',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/mapa_bmclt7.svg',
      alt: 'Icon Mapa',
    },
  ];

  public url = document.location.href;
  public map: string = '';

  constructor() {
    switch (this.url) {
      case 'https: //pe.visatravelcenter.com/':
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        break;
      case 'https: //co.visatravelcenter.com/':
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        break;
      case 'https: //bo.visatravelcenter.com/':
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        break;
      case 'https: //ec.visatravelcenter.com/':
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        break;
      default:
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
    }
  }

  ngOnInit(): void {}
}
