import { env } from './.env';

export const environment = {
  production: false,
  version: env['npm_package_version'] + '-dev',
  serverUrl: 'https://vtc-api-qa.pervolare.net',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'es-ES', 'pt-BR'],
  domain: 'https://www.visatravelcenter.com',
  messageBird: '',
  salesForceUrl: 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DD20000001w2F',
  stripeKey:
    'pk_test_51KlCu6Ajt5F1abx3LcbzYDkjm85c9nf65UoNHTXqCWNr5k7CXnlheQ9FxgQMDyYPXg4yGEDTtG3xR2tUNPsNfSA900uuMMHWmL',
};
