import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@app/services/customer.service';
import { VisasService } from '@app/services/visas.service';
import { AppTextSettings } from '../../text-config/text-country';
import { HelpCenterInfo } from './help-center-info.component';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent implements OnInit {
  public listvisas: any = [];
  public loading: boolean = !1;
  public url = document.location.href;
  public ref_pais: number;
  public map: string = '';
  public direccion: string = '';
  public formSent: boolean = false;
  public texts: any;
  public categories: any;
  public categoriesFilter: any;
  public inputFilter = '';
  public firstCond = false;
  public secondCond = false;
  public asesoria = '';
  public asesoriaEstudios = '';
  public asesoriaEspana = '';
  public asesoriaAmericana = '';

  arrow_toggle = false;
  idToggle = 0;

  // // Toggle Arrow FAQ
  // toggleArrow(nClass: any) {
  //   console.log(nClass);
  //   this.idToggle = nClass;
  //   this.arrow_toggle = !this.arrow_toggle;
  // }

  category: any = [
    {
      collapse: 'collapseOne',
      collapse2: '#collapseOne',
    },
  ];

  // properties html image
  propsComponent: any = [
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657561543/VTC%20-%20IMAGES/VTC_svckam_rzp8ul.webp',
      alt: 'Logo Visa Travel Center',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040006/VTC%20-%20IMAGES/facebook_yunfqs.svg',
      alt: 'Icon Facebook',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/instagram_okrkez.svg',
      alt: 'Icon Instagram',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040010/VTC%20-%20IMAGES/tiktok_hwcttx.svg',
      alt: 'Icon Tiktok',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/mapa_bmclt7.svg',
      alt: 'Icon Mapa',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1658426312/VTC%20-%20IMAGES/faq/fecha_itfrtw.webp',
      alt: 'Ico Flecha',
    },
  ];

  public help_center: any;
  // public propsComponent: any;
  public div1: any;
  public div2: any;
  public currentHelp = '';
  public idBug: any;

  constructor(
    private visasService: VisasService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private route: Router,
    private router: ActivatedRoute
  ) {
    this.currentHelp = this.router.snapshot.params['slug'];
    switch (this.url) {
      case 'https: //pe.visatravelcenter.com/':
        this.ref_pais = 179;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Colombia:  Local 4 Carrera 13 94 A - 26,  Bogotá';
        this.texts = AppTextSettings.PE;
        break;
      case 'https: //co.visatravelcenter.com/':
        this.ref_pais = 44;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Colombia:  Local 4 Carrera 13 94 A - 26,  Bogotá';
        this.texts = AppTextSettings.CO;
        break;
      case 'https: //bo.visatravelcenter.com/':
        this.ref_pais = 27;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Colombia:  Local 4 Carrera 13 94 A - 26,  Bogotá';
        this.texts = AppTextSettings.BO;
        break;
      case 'https: //ec.visatravelcenter.com/':
        this.ref_pais = 57;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Ecuador:  La Pinta E4 - 451,  Local 2,  Juan León Mera,  Quito 170522';
        this.texts = AppTextSettings.EC;
        break;
      default:
        this.ref_pais = 65;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Colombia:  Local 4 Carrera 13 94 A - 26,  Bogotá';
        this.texts = AppTextSettings.DEFAULT;
    }
    switch (this.currentHelp) {
      case 'servicios':
        this.help_center = HelpCenterInfo.SERVICES;
        this.idBug = 2;
        break;
      case 'citas':
        this.help_center = HelpCenterInfo.QUOTES;
        break;
      case 'justificacion-economica':
        this.help_center = HelpCenterInfo.ECONOMIC;
        break;
      case 'estudios-de-perfil':
        this.help_center = HelpCenterInfo.STUDIES;
        this.idBug = 1;
        break;
      case 'requisitos-visas':
        this.help_center = HelpCenterInfo.REQUIREMENTS;
        this.idBug = 1;
        break;
      case 'contacto':
        this.help_center = HelpCenterInfo.DEFAULT;
        break;
      default:
        this.currentHelp = 'default';
        this.help_center = HelpCenterInfo.DEFAULT;
    }
    this.propsComponent = HelpCenterInfo.PROPSCOMPONENT;

    let baseUrl = this.url.slice(0, 10);
    const url = window.location.origin;
    this.asesoria = url + '/asesoria-migratoria';
    this.asesoriaEstudios = url + '/asesoria-migratoria-estudios-exterior';
    if (baseUrl === 'https://co' || baseUrl === 'http://co.') {
      this.secondCond = true;
      this.asesoriaEspana = url + '/asesoria-migratoria-espana';
    }
    if (baseUrl === 'https://ec' || baseUrl === 'http://ec.' || baseUrl === 'https://go-') {
      this.secondCond = true;
      this.firstCond = true;
      this.asesoriaAmericana = url + '/asesoramiento-visa-americana';
      this.asesoriaEspana = url + '/asesoria-migratoria-espana';
    }

    if (baseUrl === 'https://pe' || baseUrl === 'http://pe.') {
      this.firstCond = true;
      this.asesoriaAmericana = url + '/asesoramiento-visa-americana';
    }

    if (baseUrl === 'https://bo' || baseUrl === 'http://bo.') {
      this.firstCond = true;
      this.asesoriaAmericana = url + '/asesoramiento-visa-americana';
    }
  }

  ngOnInit(): void {}

  // Toggle Arrow
  toggleArrow(nClass: any) {
    if (nClass == this.help_center[nClass - 1].id) {
      this.help_center[nClass - 1].transform = !this.help_center[nClass - 1].transform;

      if (this.help_center[nClass - 1].id != this.idBug) {
        if (this.help_center[1].transform) {
          this.div1 = document.getElementById(this.help_center[this.idBug - 1].collapse);
          if (this.div1.classList.contains('show')) {
            this.help_center[this.idBug - 1].transform = true;
          } else {
            this.help_center[this.idBug - 1].transform = !this.help_center[this.idBug - 1].transform;
          }
        }
      }
    }
  }

  toggleArrowSub(nClass: any, nClassSub: any) {
    if (
      nClass == this.help_center[nClass - 1].id &&
      nClassSub == this.help_center[nClass - 1].subcategory[nClassSub - 1].id
    ) {
      this.help_center[nClass - 1].subcategory[nClassSub - 1].transform =
        !this.help_center[nClass - 1].subcategory[nClassSub - 1].transform;

      if (this.help_center[nClass - 1].subcategory[nClassSub - 1].id != 1) {
        if (this.help_center[nClass - 1].subcategory[0].transform) {
          this.div2 = document.getElementById('collapsesub1');
          if (this.div2.classList.contains('show')) {
            this.help_center[nClass - 1].subcategory[0].transform = true;
          } else {
            this.help_center[nClass - 1].subcategory[0].transform =
              !this.help_center[nClass - 1].subcategory[0].transform;
          }
        }
      }
    }
  }

  redirectHelp(slug: string) {
    this.route.navigate(['centro-ayuda/' + slug]);
  }

  redirectFAQ() {
    this.route.navigate(['preguntas-frecuentes/']);
  }

  redirectPrivacy() {
    this.route.navigate(['politicas-privacidad/']);
  }

  redirectUs() {
    this.route.navigate(['nosotros/']);
  }

  redirectTo(uri: string) {
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => this.route.navigate([uri]));
  }

  public redirectAsesoria(): void {
    const url = window.location.origin;
    window.location.replace(url + '/asesoria-migratoria');
  }

  public redirectAsesoriaEstudios(): void {
    const url = window.location.origin;
    window.location.replace(url + '/asesoria-migratoria-estudios-exterior');
  }

  public redirectAsesoriaAmericana(): void {
    const url = window.location.origin;
    window.location.replace(url + '/asesoramiento-visa-americana');
  }

  public redirectAsesoriaEspana(): void {
    const url = window.location.origin;
    window.location.replace(url + '/asesoria-migratoria-espana');
  }
}
