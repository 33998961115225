<nav class="navbar navbar-expand-lg header-fixed p-3">
  <div class="container">
    <img
      width="1"
      height="1"
      style="width: 200px !important"
      class="img-VTC w-100"
      src="https://res.cloudinary.com/danfer13/image/upload/v1657561543/VTC%20-%20IMAGES/VTC_svckam_rzp8ul.webp"
      alt="Logo Vtc"
    />
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
      <form class="d-flex">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a routerLink="/nosotros" class="nav-link active text-white" aria-current="page" href="#">Quiénes somos</a>
          </li>
          <li class="nav-item">
            <a routerLink="/preguntas-frecuentes" class="nav-link active text-white" aria-current="page" href="#"
              >Preguntas Frecuentes</a
            >
          </li>
        </ul>
      </form>
    </div>
  </div>
</nav>
