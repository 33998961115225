import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'about',
      loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    },
    {
      path: 'cotizar',
      loadChildren: () => import('./steps/first-step/first-step.module').then((m) => m.FirstStepModule),
    },
    {
      path: 'detalle',
      loadChildren: () => import('./steps/second-step/second-step.module').then((m) => m.SecondStepModule),
    },
    {
      path: 'confirma-pago',
      loadChildren: () => import('./pages/than-you-page/than-you-page.module').then((m) => m.ThanYouPageModule),
    },
    {
      path: 'payment-error',
      loadChildren: () => import('./pages/payment-fail/payment-fail.module').then((m) => m.PaymentFailModule),
    },
    {
      path: 'condiciones-uso',
      loadChildren: () =>
        import('./pages/legal/terms-conditions/terms-conditions.module').then((m) => m.TermsConditionsModule),
    },
    {
      path: 'politicas-privacidad',
      loadChildren: () =>
        import('./pages/legal/privacy-policies/privacy-policies.module').then((m) => m.PrivacyPoliciesModule),
    },
    {
      path: 'nosotros',
      loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    },
    {
      path: 'preguntas-frecuentes',
      loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqModule),
    },
    {
      path: 'centro-ayuda',
      loadChildren: () => import('./pages/help-center/help-center.module').then((m) => m.HelpCenterModule),
    },
  ]),

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
