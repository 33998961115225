import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { CustomerService } from '@app/services/customer.service';
import { VisasService } from '@app/services/visas.service';
import { environment } from '@env/environment.prod';
import { AppTextSettings } from '../text-config/text-country';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('firstNameField') firstNameField: any;

  public listvisas: any = [];
  public form: FormGroup;
  public loading: boolean = !1;
  public url = document.location.href;
  public ref_pais: number;
  public map: string = '';
  public direccion: string = '';
  public formSent: boolean = false;
  public texts: any;
  public firstCond = false;
  public secondCond = false;
  public asesoria = '';
  public asesoriaEstudios = '';
  public asesoriaEspana = '';
  public asesoriaAmericana = '';
  public blogUrl = '';
  public slideIndex = 1;
  public autoSlide = 0;
  public recaptchaValidated = false;
  public policiesAccepted = false;
  public cityActive = 'QUITO';
  public officeActive = 'LIBERTAD';

  show = true;
  changerPromo(): void {
    this.show = !this.show;
  }

  focusOnForm(): void {
    this.firstNameField.nativeElement.focus();
  }

  // properties html image
  propsComponent: any = [
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657561382/VTC%20-%20IMAGES/Cerrar_e730wu_xfr4nm.webp',
      alt: 'Boton Cerrar',
      title: 'Boton Cerrar',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657561543/VTC%20-%20IMAGES/VTC_svckam_rzp8ul.webp',
      alt: 'Logo Visa Travel Center',
    },
    {
      wid: '100px',
      heig: '100px',
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563111/VTC%20-%20IMAGES/asesoria-migratoria_znznz3_mxfefc.webp',
      alt: 'Asesorias migratorias',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563041/VTC%20-%20IMAGES/pasaporte_sy6f5j_t2ledd.webp',
      alt: 'Pasaporte vigente',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563209/VTC%20-%20IMAGES/seguro_fc1wfy-_1__enpu2s.webp',
      alt: 'Seguro de viaje',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563260/VTC%20-%20IMAGES/justificacion_onikqd_uk8l5p.webp',
      alt: 'Justificacion economica',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563315/VTC%20-%20IMAGES/reserva_exvkyx_lienrz.webp',
      alt: 'Reserva de hotel y vuelo',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563741/VTC%20-%20IMAGES/am7vh-2u6sy_nvfwnw.webp',
      alt: 'Barra de numeros',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563784/VTC%20-%20IMAGES/a56gr-bukuz_zui8km.webp',
      alt: 'Asistencias medicas',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657563822/VTC%20-%20IMAGES/a2ung-ltqge_xuus2r.webp',
      alt: 'Agencia de viaje',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040005/VTC%20-%20IMAGES/estudio_ccugh6.png',
      alt: 'Estudio de perfil para visado',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657564045/VTC%20-%20IMAGES/a2z23-75mi9_hc2b0y.webp', // 11
      alt: 'Boletos aereos',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040009/VTC%20-%20IMAGES/Passport_i2wcmv.svg',
      alt: 'Icon Pasaporte',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040006/VTC%20-%20IMAGES/banderas-1_ciludr.svg',
      alt: 'Banderas Pasaporte',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040005/VTC%20-%20IMAGES/documentos_cswkzi.svg',
      alt: 'Icon Visas aprobadas',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657039999/VTC%20-%20IMAGES/banderas-2_uwocxq.svg',
      alt: 'Bandera Visas aprobadas',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040011/VTC%20-%20IMAGES/Viajero_ewfops.svg',
      alt: 'Icon Viajero',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/Mundo_opfm16.svg',
      alt: 'Icon Mundo - Visas aprobadas',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040005/VTC%20-%20IMAGES/estrella_vribq5.svg', //18
      alt: 'Icon Estrella - Sueño de viajeros',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040006/VTC%20-%20IMAGES/facebook_yunfqs.svg',
      alt: 'Icon Facebook',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/instagram_okrkez.svg',
      alt: 'Icon Instagram',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040010/VTC%20-%20IMAGES/tiktok_hwcttx.svg',
      alt: 'Icon Tiktok',
    },
    {
      src: 'https://res.cloudinary.com/danfer13/image/upload/v1657040008/VTC%20-%20IMAGES/mapa_bmclt7.svg',
      alt: 'Icon Mapa',
    },
  ];

  constructor(
    private visasService: VisasService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    switch (this.url) {
      case 'https://pe.visatravelcenter.com/':
        this.ref_pais = 179;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Colombia:  Local 4 Carrera 13 94 A - 26,  Bogotá';
        this.texts = AppTextSettings.PE;
        break;
      case 'https://co.visatravelcenter.com/':
        this.ref_pais = 44;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Colombia:  Local 4 Carrera 13 94 A - 26,  Bogotá';
        this.texts = AppTextSettings.CO;
        break;
      case 'https://bo.visatravelcenter.com/':
        this.ref_pais = 27;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Colombia:  Local 4 Carrera 13 94 A - 26,  Bogotá';
        this.texts = AppTextSettings.BO;
        break;
      case 'https://ec.visatravelcenter.com/':
        this.ref_pais = 57;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Ecuador:  La Pinta E4 - 451,  Local 2,  Juan León Mera,  Quito 170522';
        this.texts = AppTextSettings.EC;
        break;
      case 'https://go-prod-visatravelcenter.pervolare.net/':
        this.ref_pais = 57;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Ecuador:  La Pinta E4 - 451,  Local 2,  Juan León Mera,  Quito 170522';
        this.texts = AppTextSettings.EC;
        break;
      case 'https://visatravelcenter.com/':
        this.ref_pais = 57;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Ecuador:  La Pinta E4 - 451,  Local 2,  Juan León Mera,  Quito 170522';
        this.texts = AppTextSettings.VI;
        break;
      default:
        this.ref_pais = 65;
        this.map = 'https: //www.google.com/maps/place/Visa+Travel+Center/@4.6791709, -74.0500153, 17z';
        this.direccion = 'Dirección Colombia:  Local 4 Carrera 13 94 A - 26,  Bogotá';
        this.texts = AppTextSettings.DEFAULT;
    }
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(100),
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
      ]),
      destiny_id: new FormControl(this.ref_pais, Validators.required),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
        Validators.pattern(/^((?!\s{2,}).)*$/),
        Validators.pattern('[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*'),
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
        Validators.pattern(/^((?!\s{2,}).)*$/),
        Validators.pattern('[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*'),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern('[0-9]*'),
      ]),
    });
    let baseUrl = this.url.slice(0, 10);
    const url = window.location.origin;
    this.asesoria = url + '/asesoria-migratoria';
    this.asesoriaEstudios = url + '/asesoria-migratoria-estudios-exterior';
    this.blogUrl = url + '/blog';
    if (baseUrl === 'https://co' || baseUrl === 'http://co.') {
      this.secondCond = true;
      this.asesoriaEspana = url + '/asesoria-migratoria-espana';
    }
    if (baseUrl === 'https://ec' || baseUrl === 'http://ec.' || baseUrl === 'https://go-') {
      this.secondCond = true;
      this.firstCond = true;
      this.asesoriaAmericana = url + '/asesoramiento-visa-americana';
      this.asesoriaEspana = url + '/asesoria-migratoria-espana';
    }

    if (baseUrl === 'https://pe' || baseUrl === 'http://pe.') {
      this.firstCond = true;
      this.asesoriaAmericana = url + '/asesoramiento-visa-americana';
    }

    if (baseUrl === 'https://bo' || baseUrl === 'http://bo.') {
      this.firstCond = true;
      this.asesoriaAmericana = url + '/asesoramiento-visa-americana';
    }
  }
  ngOnInit() {
    this.showVisas();
    let baseUrl = this.url.slice(0, 10);
    this.showSlides(this.slideIndex);
    if (
      baseUrl === 'https://ec' ||
      baseUrl === 'http://ec.' ||
      baseUrl === 'http://co.' ||
      baseUrl === 'https://co' ||
      baseUrl === 'http://loc'
    ) {
      this.autoSlideShow();
    }
  }
  public showVisas() {
    this.visasService.get_visas().subscribe((resp) => {
      this.listvisas = resp;
    });
  }
  public sendCustomer() {
    this.formSent = true;
    this.customerService
      .postCustomer(environment.serverUrl + '/api/services', {
        destiny_id: this.form.value.destiny_id,
        type: 'assistance',
        data: {
          tld: 'com',
          name: this.form.value.name.trimStart() + ' ' + this.form.value.lastname.trimStart(),
          lastname: this.form.value.lastname.trimStart(),
          email: this.form.value.email.trimStart(),
          phone: this.form.value.phone.trimStart(),
          source: window.location.href,
        },
      })
      .subscribe(
        (resp: any) => {
          this.loading = !0;
          //this.router.navigate(['cotizar', resp.hashcode]);
          let arrMB = {
            landing: 'Homepage',
            numero: this.form.value.phone.trimStart(),
            origen: location.origin,
            dominio: location.origin.slice(8, 10),
            correo: this.form.value.email.trimStart(),
          };
          if (this.texts['KUSHKI_COUNTRY'] == 'EC' || this.texts['KUSHKI_COUNTRY'] == 'CO') {
            if (!this.texts['DEV']) {
              this.customerService.sendMB(arrMB, this.texts['MB']).subscribe((resp2: any) => {
                this.loading = !0;
                const url = window.location.origin;
                window.location.replace(url + '/cotizar/' + resp.hashcode.split('=').join('%3D'));
              });
            } else {
              this.loading = !0;
              const url = window.location.origin;
              window.location.replace(url + '/cotizar/' + resp.hashcode.split('=').join('%3D'));
            }
          } else {
            this.loading = !0;
            const url = window.location.origin;
            window.location.replace(url + '/cotizar/' + resp.hashcode.split('=').join('%3D'));
          }
        },
        (error) => {
          console.log(error);
          this.formSent = false;
        }
      );
  }

  public redirectAsesoria(): void {
    const url = window.location.origin;
    window.location.replace(url + '/asesoria-migratoria');
  }

  public redirectAsesoriaEstudios(): void {
    const url = window.location.origin;
    window.location.replace(url + '/asesoria-migratoria-estudios-exterior');
  }

  public redirectAsesoriaAmericana(): void {
    const url = window.location.origin;
    window.location.replace(url + '/asesoramiento-visa-americana');
  }

  public redirectAsesoriaEspana(): void {
    const url = window.location.origin;
    window.location.replace(url + '/asesoria-migratoria-espana');
  }

  public autoSlideShow(): void {
    setTimeout(() => {
      this.autoSlide = this.autoSlide + 1;
      if (this.autoSlide == 10) {
        this.plusSlides(1);
      }
      this.autoSlideShow();
    }, 1000);
  }

  public plusSlides(n: any) {
    this.showSlides((this.slideIndex += n));
    this.autoSlide = 0;
  }

  public showSlides(n: any): void {
    let i;
    let slides;
    console.log(this.texts['KUSHKI_COUNTRY']);
    if (this.texts['KUSHKI_COUNTRY'] == 'ec') {
      slides = document.getElementsByClassName('mySlidesEc');
    } else if (this.texts['KUSHKI_COUNTRY'] == 'co') {
      slides = document.getElementsByClassName('mySlidesCo');
    } else {
      slides = document.getElementsByClassName('mySlides');
    }
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      if (i == this.slideIndex - 1) {
        slides[i]['style'].display = 'block';
      } else {
        slides[i]['style'].display = 'none';
      }
    }
  }

  onCaptchaResolved(response: string) {
    console.log('Resolved captcha with response: ' + response);
    if (response && response.length > 0 && response != null && response != undefined && response != '') {
      this.recaptchaValidated = true;
    }
  }

  togglePolicies() {
    this.policiesAccepted = !this.policiesAccepted;
  }

  changeCity(city: string) {
    this.cityActive = city;
    if (city == 'QUITO') {
      this.officeActive = 'LIBERTAD';
    } else if (city == 'GUAYAQUIL') {
      this.officeActive = 'CENTRO';
    } else {
      this.officeActive = 'CUENCA';
    }
  }

  changeOffice(office: string) {
    this.officeActive = office;
  }
}
