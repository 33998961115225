<div class="container-fluid px-0">
  <img
    width="1"
    height="1"
    src="/assets/images/main-banner.png"
    alt="logo-orbita"
    style="width: 100%; height: auto;"
  />
</div>

<div class="container mt-5" style="background-color: white; border-radius: 30px; padding: 30px">
  <div class="row">
    <div class="col-12">
      <h3 class="visa-title" style="margin-bottom: 0px;">Saca la visa con nosotros</h3>
      <p>¡La primera asesoría es gratis!</p>
    </div>
  </div>
  <div class="row">
    <div class="col-3 text-left">
      <img height="auto" width="100" src="/assets/images/visa-1.png" style="width: 70%;">
    </div>
    <div class="col-3 text-center">
      <img height="auto" width="100" src="/assets/images/visa2.png" style="width: 70%;">
    </div>
    <div class="col-3 text-center">
      <img height="auto" width="100" src="/assets/images/visa-3.png" style="width: 70%;">
    </div>
    <div class="col-3 text-center">
      <img height="auto" width="100" src="/assets/images/visa-4.png" style="width: 70%;">
    </div>
  </div>
</div>

<div class="container mb-3 mt-5">
  <div class="row">
    <h3 class="visa-title">¿A dónde te llevará tu próxima visa?</h3>
    <p>Planea tu próximo viaje y adquiere las mejores ofertas.</p>
  </div>
  <div class="row">
    <div class="col-3 text-center">
      <img height="auto" width="100" src="/assets/images/caja1.png" style="width: 100%;">
    </div>
    <div class="col-3 text-center">
      <img height="auto" width="100" src="/assets/images/caja2.png" style="width: 100%;">
    </div>
    <div class="col-3 text-center">
      <img height="auto" width="100" src="/assets/images/caja3.png" style="width: 100%;">
    </div>
    <div class="col-3 text-center">
      <img height="auto" width="100" src="/assets/images/caja4.png" style="width: 100%;">
    </div>
  </div>
</div>


<div class="container-fluid px-0 mt-5">
  <img height="auto" width="100" src="/assets/images/reviews.png" style="width: 100%;">
</div>

<footer class="container-fluid footer-bg footer pb-4">
  <div class="container d-none d-lg-block">
    <div class="row pt-5">
      <div class="col">
          <p class="text-white footer-title">Nuestros locales</p>
          <p class="footer-title mt-4" style="font-size: 13px;">Ecuador</p>
          <span class="mt-3" style="font-weight: 300; font-size: 13px; color: white">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight: bold;">Quito.</span><br>
            Santa María y Reina Victoria, <span style="font-weight: bold;">Quito.</span><br>
            La Pinta y Juan León Mera, <span style="font-weight: bold;">Quito.</span><br>
            Vélez y Lizardo García, <span style="font-weight: bold;">Quito.</span><br>
            Bosques del Salado y Rodrigo Chávez G, <span style="font-weight: bold;">Guayaquil.</span><br>
            Presidente Córdova y Benigno Malo, <span style="font-weight: bold;">Cuenca.</span></span>
          <div class="color-primary mt-3 text-center" style="background-color: white; border-radius: 30px; padding: 10px; font-weight: bold; font-size: 13px; width: 80%">Ver locales en Ecuador </div>
      </div>
      <div class="col">
        <p class="footer-title">Ventas</p>
        <p style="margin-bottom: 0px;">
          <img
            width="18"
            height="20"
            class="ws-footer"
            src="/assets/images/whatsapp.png"
            alt="visatravelcenter whatsapp"
            style="margin-right: 2px; margin-bottom: 2px"
          />
          <span class="text-white" style="font-weight: 600; font-size: 18px">+593 961 459 059</span>
          <img
          width="15"
          height="15"
          class="ws-footer"
          src="/assets/images/ecuador.png"
          alt="visatravelcenter whatsapp colombia"
          style="margin-left: 5px; margin-bottom: 5px"
        />
        </p>
        <p style="margin-top: 5px;">
          <img
            width="18"
            height="20"
            class="ws-footer"
            src="/assets/images/whatsapp.png"
            alt="visatravelcenter whatsapp"
            style="margin-right: 2px; margin-bottom: 2px"
          />
          <span class="text-white" style="font-weight: 600; font-size: 18px">+57 312 55 01 225</span>
          <img
          width="15"
          height="15"
          class="ws-footer"
          src="/assets/images/colombia.png"
          alt="visatravelcenter whatsapp colombia"
          style="margin-left: 5px; margin-bottom: 5px"
        />
        </p>
        <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
          Sábados de 9 a 13 hs</p>
      </div>
      <div class="col">
        <p class="footer-title">Productos</p>
        <p class="products-list">
          <span>Visas</span>
        </p>
        <p class="products-list">
          <span>Seguros de viajes</span>
        </p>
        <p class="products-list">
          <span>Paquetes</span>
        </p>
        <a href="https://go.visatravelcenter.com/blog/" style="text-decoration: none;">
          <p class="text-white footer-title"><span class="font-size-15 bolder-d gris-claro">Blog</span><br /></p>
        </a>
      </div>
      <div class="col">
        <p class="footer-title">Legales</p>
        <p class="text-white" style="font-weight: 400; font-size: 14px">
          Cumplimos con los permisos exigidos. ÓRBITA GROUP NÚMERO LUAE: 474452. NÚMERO DE TRÁMITE: 2023WEBLUAE30731. MARCA OPERADA POR: ÓRBITA GROUP- SEVIFRAL S.A.
        </p>
      </div>
      <div class="col">
        <div>
          <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
            <div class="mt-3">
              <span class="mt-4">
                <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-fb.png"
                    alt="visatravelcenter facebook"
                    style="margin-right: 10px;"
                  />
                </a>
                <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-ig.png"
                    alt="visatravelcenter Instagram"
                    style="margin-right: 10px;"
                  />
                </a>
                <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
                  <img
                    width="30"
                    height="30"
                    class="red-social"
                    src="/assets/images/social-tk.png"
                    alt="visatravelcenter tiktok"
                    style="margin-right: 10px;"
                  />
                </a> </span>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-lg-none pb-5">
    <div class="col-12 mt-3 text-center">
      <p class="text-white footer-title">Nuestros locales</p>
      <p class="footer-title mt-4" style="font-size: 13px; padding-bottom: 1px">Ecuador</p>
      <span class="mt-3" style="font-weight: 00; font-size: 13px; color: white">La Pinta y Amazonas. Edificio Libertad, <span style="font-weight: bold;">Quito.</span><br>
        Santa María y Reina Victoria, <span style="font-weight: bold;">Quito.</span><br>
        La Pinta y Juan León Mera, <span style="font-weight: bold;">Quito.</span><br>
        Vélez y Lizardo García, <span style="font-weight: bold;">Quito.</span><br>
        Bosques del Salado y Rodrigo Chávez G, <span style="font-weight: bold;">Guayaquil.</span><br>
        Presidente Córdova y Benigno Malo, <span style="font-weight: bold;">Cuenca.</span></span>
      <div class="text-center mt-4">
        <a class="color-primary mt-3 text-center" style="background-color: white; border-radius: 30px; padding: 10px; font-weight: bold; font-size: 13px; width: 80%; text-decoration: none; margin-top: 14px">Ver locales en Ecuador </a>
      </div>
    </div>
    <div class="col-12 text-center mt-4">
      <p class="footer-title">Ventas</p>
    <p style="margin-bottom: 0px;">
      <img
        width="18"
        height="20"
        class="ws-footer"
        src="/assets/images/whatsapp.png"
        alt="visatravelcenter whatsapp"
        style="margin-right: 2px; margin-bottom: 2px"
      />
      <span class="text-white" style="font-weight: 600; font-size: 18px">+593 961 459 059</span>
      <img
      width="15"
      height="15"
      class="ws-footer"
      src="/assets/images/ecuador.png"
      alt="visatravelcenter whatsapp colombia"
      style="margin-left: 5px; margin-bottom: 5px"
    />
    </p>
    <p style="margin-top: 5px;">
      <img
        width="18"
        height="20"
        class="ws-footer"
        src="/assets/images/whatsapp.png"
        alt="visatravelcenter whatsapp"
        style="margin-right: 2px; margin-bottom: 2px"
      />
      <span class="text-white" style="font-weight: 600; font-size: 18px">+57 312 55 01 225</span>
      <img
      width="15"
      height="15"
      class="ws-footer"
      src="/assets/images/colombia.png"
      alt="visatravelcenter whatsapp colombia"
      style="margin-left: 5px; margin-bottom: 5px"
    />
    </p>
    <p class="mt-3" style="font-weight: 300; font-size: 13px; color: white; line-height: 1.2; padding-right: 20px">De lunes a viernes de 8 a 17 hs
      Sábados de 9 a 13 hs</p>
  </div>
  <div class="col-12 text-center">
    <span style="color: white; font-size: 14px; font-weight: bold">Síguenos en</span>
      <span class="mt-4">
        <a target="_blank" href="https://www.facebook.com/visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-fb.png"
            alt="visatravelcenter facebook"
            style="margin-right: 10px;"
          />
        </a>
        <a target="_blank" href="https://www.instagram.com/visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-ig.png"
            alt="visatravelcenter Instagram"
            style="margin-right: 10px;"
          />
        </a>
        <a target="_blank" href="https://www.tiktok.com/@visatravelcenter/">
          <img
            width="30"
            height="30"
            class="red-social"
            src="/assets/images/social-tk.png"
            alt="visatravelcenter tiktok"
            style="margin-right: 10px;"
          />
        </a> </span>
  </div>
  </div>
</footer>

<div class="container-fluid d-none d-lg-block" style="background-color: #183755">
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-top: 20px; padding-bottom: 20px; font-weight: 300">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px;"
      />
      2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.
    </div>
  </div>
</div>

<div class="container-fluid d-lg-none" style="background-color: #183755">
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-top: 20px; padding-bottom: 20px">
        <img
          width="180"
          height="40"
          class="red-social"
          src="assets/images/vtc.png"
          alt="logo vtc"
          style="margin-right: 20px;"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-white text-center" style="padding-bottom: 20px; font-weight: 400">
      <span style="font-weight: 300">2024 All Rights Reservered. Asistencia Médica con Cobertura Mundial.</span>
    </div>
  </div>
</div>
